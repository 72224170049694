import React from 'react';
import handleStrapiUrl from '../../../lib/handle-strapi-url';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Hero = ({props}) => {
    console.log('hero props: ', props.fund.data.attributes.hero.data.attributes.url)
    return (
        <div 
            className="l-hero-funds" 
            style={{
                backgroundImage: `url(${handleStrapiUrl(props.fund.data.attributes.hero.data.attributes.url)})`
            }}
        >
            <div className="m-0 row w-100">
                <div className="col-lg-5 d-flex flex-column justify-content-center order-2 order-lg-1">
                    <h2 className="l-hero-funds__title">
                        {props.fund.data.attributes.title}
                    </h2>
                    <div className="l-hero-funds__text h-auto">
                        <ReactMarkdown 
                            children={props.fund.data.attributes.body}  
                            remarkPlugins={[remarkGfm]}
                        />
                    </div>
                    
                </div>
                <div className="col-lg-7 order-1">
                    <div className="l-hero-funds__image mb-5 mb-lg-0">
                        <img src={handleStrapiUrl(props.fund.data.attributes.icon.data.attributes.url)} alt=""/>
                    </div>
                </div>
                {props?.fund?.data?.attributes?.display_fund_btn && (
                    <div style={{height: '80px'}} className="order-3">
                        {props?.fund?.data?.attributes?.display_fund_btn?.display === true && (
                            <a 
                                target='_blank' 
                                className="c-btn c-btn--primary l-hero-funds__btn" 
                                rel="noreferrer" 
                                href={props.fund.data.attributes.display_fund_btn?.url}
                            >
                                {props.fund.data.attributes.display_fund_btn?.title}
                            </a>
                        )}
                    </div>
                )}
                
            </div>
            <a href="#milestones" className="c-btn-scrollDown c-btn-scrollDown--green"> 
                <span className="icon-arrow-down"></span>
            </a>
        </div>
    )
}

export default Hero