import React from 'react';
import handleStrapiUrl from '../../lib/handle-strapi-url';

const AccordionDocumentItem = ({
    id,
    title,
    quantity,
    files
}) => {
    let tempFiles = [...files];
    tempFiles = tempFiles.length > 1 ? tempFiles.reverse() : tempFiles;
    console.log('AccordionDocumentItem', files);
    
    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id={`heading${id}`}>
                <button 
                    className="accordion-button collapsed" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target={`#collapse${id}`} 
                    aria-expanded="true" 
                    aria-controls={`collapse${id}`}
                >
                    <div className="mr-auto">
                        {title}
                    </div>
                </button>
            </h2>
            <div id={`collapse${id}`} className="accordion-collapse collapse" aria-labelledby={`heading${id}`} data-bs-parent="#accordionDocument">
                <div className="accordion-body p-0">
                    {tempFiles?.map(doc => (
                        <div key={`accordion-document__item-${doc?.id}`} className="c-accordion-document__item">
                            <div className="c-accordion-document__name">
                                {doc?.name}
                            </div>
                            <a 
                                target="_blank"
                                rel="noreferrer"
                                href={handleStrapiUrl(doc?.pdf?.data[0]?.attributes?.url)}
                                className="c-accordion-document__button"
                            >
                                Descargar PDF
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AccordionDocumentItem