import {useState, useRef, useEffect} from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import FundLinks from '../fund-links';
import CorporativeLinks from '../corporative-links';
import logo_png from '../../assets/images/logo.png';
import logo_white_png from '../../assets/images/logo-white.png';

const Header = ({background = "", tabSwitch}) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const sidebar = useRef(null);
  const toogleSidebar = () => {
    if(isOpen){
      sidebar.current.classList.add('is-open');
    }else{
      sidebar.current.classList.remove('is-open');
    }
  }
  const howInvestTabSwitch = (switchTab) => {
    sessionStorage.setItem('howInvestTab', switchTab);
    if(window.location.pathname === "/how-to"){
      window.reload();
    }
  }
  
  useEffect(() => {
    toogleSidebar();
  }, [isOpen]);
  return (
      <header className={`c-header ${background}`}>
          <Link to="/" className="c-header__logo">
              {background === "c-header--white" ? (
                  <img src={logo_png} alt="" />
              ) : (
                  <img src={logo_white_png} alt="" />
              )}
          </Link>
          <ul className="c-header__nav">
              <li className="dropdown">
                  <a
                      className="c-header__item"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >
                      Conócenos
                      <span className="icon-arrow-down"></span>
                  </a>
                  <ul class="dropdown-menu">
                      <li>
                          <Link class="dropdown-item" to="/about">
                              ¿Quiénes somos?
                          </Link>
                      </li>
                      <li>
                          <HashLink class="dropdown-item" to="/about#timeLine">
                              Línea de Tiempo
                          </HashLink>
                      </li>
                      <li>
                          <HashLink class="dropdown-item" to="/about#ourTeam">
                              Nuestro Equipo
                          </HashLink>
                      </li>
                  </ul>
              </li>
              <li className="dropdown">
                  <a
                      className="c-header__item"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >
                      Fondos de Inversión
                      <span className="icon-arrow-down"></span>
                  </a>
                  <ul className="dropdown-menu">
                      <li>
                          <Link to="/investment-fund" className="dropdown-item">
                              ¿Qué son los Fondos de Inversión?
                          </Link>
                      </li>
                      <FundLinks />
                  </ul>
              </li>
              <li className="dropdown">
                  <a
                      className="c-header__item"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >
                      ¿Cómo Invertir?<span className="icon-arrow-down"></span>
                  </a>
                  <ul className="dropdown-menu">
                      <li>
                          <Link
                              to="/how-to"
                              className="dropdown-item"
                              onClick={() => howInvestTabSwitch("open")}
                          >
                              Fondos Abiertos
                          </Link>
                      </li>
                      <li>
                          <Link
                              to="/how-to"
                              className="dropdown-item"
                              onClick={() => howInvestTabSwitch("close")}
                          >
                              Fondos Cerrados
                          </Link>
                      </li>
                      <li>
                          <Link to="/faq" className="dropdown-item">
                              Preguntas Frecuentes
                          </Link>
                      </li>
                  </ul>
              </li>
              <li className="c-header__buttons">
            <a className="c-btn c-btn--outline" style={{'textTransform': 'none'}} rel='noopener noreferrer' href="https://portal.altiosafi.com" target="blank">ALTIO en LÍNEA</a>
                {/* { <Link to="/about" className="c-btn c-btn--primary">Crear mi cuenta</Link> } */}
            </li>
          </ul>
          <div ref={sidebar} className="c-header-sidebar">
              <button
                  type="button"
                  id="btnClose"
                  className="btn-close btn-close-white"
                  aria-label="Close"
                  onClick={() => setIsOpen(false)}
              ></button>
              <div className="c-header-sidebar__body">
                  <ul className="c-header-sidebar__nav">
                      <li className="dropdown">
                          <a
                              className="c-header__item"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                          >
                              Conócenos<span className="icon-arrow-down"></span>
                          </a>
                          <ul className="dropdown-menu">
                              <li>
                                  <Link to="/about" className="dropdown-item">
                                      ¿Quiénes somos?
                                  </Link>
                              </li>
                              <li>
                                  <HashLink
                                      to="/about#timeLine"
                                      className="dropdown-item"
                                  >
                                      Línea de Tiempo
                                  </HashLink>
                              </li>
                              <li>
                                  <HashLink
                                      to="/about#ourTeam"
                                      className="dropdown-item"
                                  >
                                      Nuestro Equipo
                                  </HashLink>
                              </li>
                          </ul>
                      </li>
                      <li className="dropdown">
                          <a
                              className="c-header__item"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                          >
                              Fondos de Inversión
                              <span className="icon-arrow-down"></span>
                          </a>
                          <ul className="dropdown-menu">
                              <li>
                                  <Link
                                      to="/investment-fund"
                                      className="dropdown-item"
                                      href="faq/invesment-funds.html"
                                  >
                                      ¿Qué son los Fondos de Inversión?
                                  </Link>
                              </li>
                              <FundLinks />
                          </ul>
                      </li>
                      <li className="dropdown">
                          <a
                              className="c-header__item"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                          >
                              ¿Cómo Invertir?
                              <span className="icon-arrow-down"></span>
                          </a>
                          <ul className="dropdown-menu">
                              <li>
                                  <Link
                                      to="/how-to"
                                      className="dropdown-item"
                                      onClick={() => howInvestTabSwitch("open")}
                                  >
                                      Fondos Abiertos
                                  </Link>
                              </li>
                              <li>
                                  <Link
                                      to="/how-to"
                                      className="dropdown-item"
                                      onClick={() =>
                                          howInvestTabSwitch("close")
                                      }
                                  >
                                      Fondos Cerrados
                                  </Link>
                              </li>
                              <li>
                                  <Link to="/faq" className="dropdown-item">
                                      Preguntas Frecuentes
                                  </Link>
                              </li>
                          </ul>
                      </li>
                      <li className="dropdown">
                          <a
                              className="c-header__item"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                          >
                              Información Corporativa
                              <span className="icon-arrow-down"></span>
                          </a>
                          <ul className="dropdown-menu">
                              <li>
                                  <Link
                                      className="dropdown-item"
                                      to="/memories"
                                  >
                                      Memorias
                                  </Link>
                              </li>
                              <CorporativeLinks />
                          </ul>
                      </li>
                      <li>
                          <Link to="/contact" className="c-header__item">
                              Contacto
                          </Link>
                      </li>
                      <li>
                          <Link to="/news" className="c-header__item">
                              Noticias
                          </Link>
                      </li>
                      <li>
                          <a
                              className="c-header__item"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://altiosafi.kiteworks.com/#/"
                          >
                              Portal Institucional
                          </a>
                      </li>
                      <li>
                          <Link to="/work-with-us" className="c-header__item">
                              Se Parte de Nuestro Equipo
                          </Link>
                      </li>
                  </ul>
                   <div className="c-header-sidebar__bottom">
                <a className="c-btn c-btn--outline" style={{'textTransform': 'none'}} rel='noopener noreferrer' href="https://portal.altiosafi.com" target="blank">ALTIO en LÍNEA</a>
                 {/* <Link to="/about" className="c-btn c-btn--primary">Crear mi cuenta</Link>  */}
            </div> 
              </div>
              <div className="c-header-sidebar__footer">
                  <p>¿Conoces alguna irregularidad? </p>
                  <Link to="/complaint" className="c-btn c-btn--outline">
                      Denunciar / Reclamar
                  </Link>
              </div>
          </div>
          <button
              className="c-header__button"
              id="btnMenu"
              onClick={() => setIsOpen(true)}
          >
              <span className="icon-menu"></span>
          </button>
      </header>
  );
}

export default Header