import React from 'react';
import Slider from 'react-slick';
import Card from '../card';
import { useQuery, gql } from '@apollo/client';

const NEWS = gql`
    query GetArticles {
        articles(pagination: { limit: 6 }, sort: "publishedAt:desc") {
            data {
                id
                attributes {
                    title
                    slug
                    body
                    date
                    thumbnail {
                        data {
                            id
                            attributes {
                                url
                            }
                        }
                    }
                    createdAt
                    updatedAt
                    publishedAt
                }
            }
        }
    }
`

const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    variableWidth: false,
    autoplay: true,
    responsive: [
        {
          breakpoint: 1370,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            variableWidth: false,
          },
        },
        {
          breakpoint: 1250,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            variableWidth: false,
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            variableWidth: true,
            autoplay: true,
            arrows: true,
          },
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            variableWidth: true,
            autoplay: true,
            arrows: false,
          },
        },
    ],
};

const Carousel = () => {
    const { loading, error, data } = useQuery(NEWS);
    
    if(error){
        console.log('HomeSlider: ', error);
    };

    if(loading){
        return <p>Cargando Noticias...</p>
    }

    return (
        <Slider {...settings}>
            {data?.articles?.data?.map(article => (
                <Card 
                    id={article.id}
                    key={`article${article.id}`}
                    title={article.attributes?.title}
                    body={article.attributes?.body}
                    date={article.attributes?.date}
                    publishedAt={false}
                    thumbnail={article.attributes?.thumbnail?.data?.attributes?.url}
                />
            ))}
        </Slider>
    )
}

export default Carousel