import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Hero from './hero';
import SectionMilestone from './section-milestone';
import SectionTab from './section-tab';
import { useQuery, gql } from '@apollo/client';

const FUND_BY_ID = gql`
  query GetFund($id: ID!) {
    fund(id: $id) {
      data {
        id
        attributes {
          title
          body
          hero {
            data {
              id
              attributes {
                url
                alternativeText
                caption
                width
                height
                createdAt
                updatedAt
              }
            }
          }
          icon {
            data {
              id
              attributes {
                url
                alternativeText
                caption
                width
                height
                createdAt
                updatedAt
              }
            }
          }
          Milestone{
            id
            LeftText
            RightText
          }
          Tabs {
            __typename
            ... on ComponentTeamTeam {
              Members (pagination: { limit: -1 }){
                id
                fullname
                role
                linkedin
                body
                picture {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
            __typename
            ... on ComponentPowerbiPowerbi {
              title
              source
            }
            __typename
            ... on ComponentInfoInfo {
              LeftSide {
                title
                data
              }
              RightSide {
                title
                subtitle
              }
            }
            __typename
            ... on ComponentActivesActives {
              properties {
                id
                title
                body
                subtitle
                thumbnail {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
            __typename
            ... on ComponentCategoryCategory {
              archive {
                data {
                  id
                  attributes {
                    Documents {
                      id
                      title
                      Files (pagination: { limit: -1 }){
                        id
                        name
                        pdf {
                          data {
                            id
                            attributes {
                              url
                              size
                              updatedAt
                              createdAt
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          display_fund_btn{
            display
            title
            url
          }
          createdAt
          updatedAt
          publishedAt
        }
      }
    }
  }
`

const Fund = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(FUND_BY_ID, {
    variables: { id: id }
  });

  if(error){
    console.log('InvestmentItem: ', error);
  };

  if(loading){
    return <p>Cargando Fund...</p>
  }

  return (
    <>
      <Header background={"c-header--white"}/>
      <Hero props={data}/>
      <SectionMilestone props={data?.fund?.data?.attributes?.Milestone}/>
      <SectionTab props={data}/>
      <Footer/>
    </>
  )
}

export default Fund