import React, {useState, useEffect} from 'react';
import {ScrollToTop} from '../../lib/scroll';

const ScrollTopButton = () => {
    const [visible, setVisible] = useState(false);
    const MAX_SCROLL = 800;
    const handleScroll = () => {
        if (window.scrollY > MAX_SCROLL) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    return (
        <>
            {visible && (
                <button
                id="scrollButton"
                onClick={ScrollToTop}
                className="c-btn-scrollUp"
                title="Go to top"
            >
                <span className="icon-arrow-up"></span>
            </button>
            )}
        </>
    )
}

export default ScrollTopButton