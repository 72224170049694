import React from 'react';
import handleStrapiUrl from '../../lib/handle-strapi-url';
import just_logo_icon_svg from '../../assets/images/just-logo-icon.svg';

const SliderCenterItem = ({
    id,
    title,
    subtitle,
    docNumber,
    pdf
}) => {
  return (
    <>
        <div id={`memory-item-${id}`} className="c-slider-doc__item">
            <div className="c-slider-doc__document">
                <div className="c-slider-doc__title">{subtitle}</div>
                <div className="c-slider-doc__subtitle">{title}</div>
                <div className="c-slider-doc__number">{docNumber}</div>
                <div className="c-slider-doc__icon">
                    <img src={just_logo_icon_svg} alt=""/>
                </div>
            </div>
            <div className="c-slider-doc__name">{pdf.name}</div>
            <a href={handleStrapiUrl(pdf.url)} rel="noreferrer" target="_blank" className="c-slider-doc__button">
                Descargar
            </a>
        </div>
    </>
  )
}

export default SliderCenterItem