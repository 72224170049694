import React from 'react'
import { Link } from "react-router-dom";
import Carousel from '../../../components/carousel';
import newspaper_svg from '../../../assets/images/newspaper.svg';

const SectionNews = () => {
    return (
        <section className="section-news">
            <div className="section-news__header">
                <img src={newspaper_svg} alt="" className="section-news__icon"/>
                <div>
                    <h3 className="section-news__title">Noticias</h3>
                    <h4 className="section-news__subtitle">Mantente al tanto de nuestras novedades y eventos</h4>
                </div>
            </div>
            <div className="section-news__body">
                <div className="c-slider-news">
                    <Carousel />
                </div>
            </div>
            <div className="section-news__footer">
                <p>¿Quiere ver más de nuestras noticias?</p>
                <Link to="/news" className="c-btn c-btn--primary">VER TODAS LAS NOTICIAS</Link>
            </div>
        </section>
    )
}

export default SectionNews