import React from 'react';
import { Link } from "react-router-dom";
import footer_sign_jpg from '../../assets/images/footer-sign.png';
import Logo_Adosafi_svg from '../../assets/images/Logo-Adosafi.svg';

const Footer = () => {
  return (
    <footer className="c-footer">
      <div className="row w-100 m-0">
        <div className="col-lg-4 mb-lg-0 mb-5">
          <div className="c-footer__title">
            Liderando a través de la innovación.
          </div>
          <span className="c-footer__logos" id="CertificacionUAF">
            <a 
              href="https://certificaciones.uaf.gob.do/certificaciones_so_view.php?editid1=259"
              title="Cerficación Sujeto Obligado - Unidad de Análisis Financiero" 
              target="_blank"
              rel="noreferrer"
              hrefLang="es"
            >
              <img src={footer_sign_jpg} alt="Sello de Certificaci&oacute;n de Sujeto Obligado" width="83" height="100" lang="es" />
            </a>
          </span>
          <span className="c-footer__logos" id="LogoAdosafi">
            <img src={Logo_Adosafi_svg} alt="Sello de Certificaci&oacute;n de Sujeto Obligado" width="83" height="100" lang="es" />
          </span>
        </div>
        <div className="col-lg-2">
          <ul className="c-footer__list">
            <li><Link to="/investment-fund">Fondos de inversión</Link></li>
            <li><Link to="/about">Cónocenos</Link></li>
            <li><Link to="/news">Sala de prensa</Link></li>
          </ul>
        </div>
        <div className="col-lg-2 mb-lg-0 mb-5">
          <ul className="c-footer__list">
            <li><Link to="/how-to">Invertir</Link></li>
            <li><Link to="/memories">Memorias Anuales</Link></li>
            <li><Link to="/contact">Contacto</Link></li>
          </ul>
        </div>
        <div className="col-lg-4 text-lg-end">
          <p className="mb-5">Torre Mil57, 3 Piso Av. Abraham lincoln No. 1057, Santo Domingo. Rep. Dom.</p>
          <p><b>info@altiosafi.com</b></p>
          <p><b>Tel. 809.289.7336</b></p>
          <p><b>Síguenos en las redes</b></p>
          <div className="c-footer__social">
            <a style={{textDecoration: 'none'}} href="https://instagram.com/altiosafird?igshid=MzRlODBiNWFlZA==" rel="noreferrer" target='_blank'><span className="icon-instagram"></span></a>
            <a style={{textDecoration: 'none'}} href="https://www.linkedin.com/company/altiosafi/" rel="noreferrer" target='_blank'><span className="icon-linkedin"></span></a>
          </div>
        </div>
      </div>
      <div className="c-footer__copy">
        <p>©️ 2024 ALTIO SAFI. Todos los derechos reservados | <a style={{color: "white", textDecoration: "none"}} href="https://altiosaficmsuploadsprod.blob.core.windows.net/uploads/assets/ALTIO_TERMINOS_Y_CONDICIONES_Y_POLITICA_DE_PRIVACIDAD_3e56968c40.pdf" target="_blank" rel="noreferrer">Términos, Condiciones y Políticas de Privacidad.</a></p>
      </div>
    </footer>
  )
}

export default Footer