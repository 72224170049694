import React from 'react';
import { Link } from "react-router-dom";
import invierte_svg from '../../../assets/images/invierte.svg';
import diversifica_svg from '../../../assets/images/diversifica.svg';
import accede_svg from '../../../assets/images/accede.svg';
import handleStrapiUrl from '../../../lib/handle-strapi-url';
import { useQuery, gql } from '@apollo/client';

const HOW_INVEST = gql`
  query GetInvest {
    invest {
      data {
        id
        attributes {
          how_invest_button {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`

const SectionInvestment = () => {
  const { loading, error, data } = useQuery(HOW_INVEST);
  const how_to_invest_button = data?.invest?.data.attributes?.how_invest_button?.data 
    ? data?.invest?.data?.attributes?.how_invest_button?.data?.attributes?.url
    : null;

  if(error){
    console.log('SLIDESHOW: ', error);
  }

  if(loading){
    return <p>Cargando SectionInvestment...</p>
  }

  return (
    <section className="section-investment" id="investment">
      <div className="section-investment__content">
        <div className="section-investment__item">
          <div className="section-investment__icon">
            <img src={invierte_svg} alt=""/>
          </div>
          <p className="section-investment__text">
            Invierte en nuestros fondos que impulsan el desarrollo de la economía nacional.
          </p>        
        </div>
        <div className="section-investment__item">
          <div className="section-investment__icon">
            <img src={diversifica_svg} alt=""/>
          </div>
          <p className="section-investment__text">
            Diversifica tu capital junto al mejor <b>equipo de expertos.</b>
          </p>        
        </div>
        <div className="section-investment__item">
          <div className="section-investment__icon">
            <img src={accede_svg} alt=""/>
          </div>
          <p className="section-investment__text">
            <b>Accede</b> a las mejores y más <b>innovadoras inversiones.</b>
          </p>        
        </div>
        {how_to_invest_button ? (
          <a 
            target='_blank' 
            className="c-btn c-btn--primary" 
            rel="noreferrer" 
            href={handleStrapiUrl(data?.invest?.data?.attributes?.how_invest_button?.data?.attributes?.url)}
          >
            CÓMO INVERTIR
          </a>
        ) : (
          <Link to='/how-to' className="c-btn c-btn--primary">CÓMO INVERTIR</Link>
        )}
      </div>
    </section>
  )
}

export default SectionInvestment