import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Modal from '../modal';
import TeamItem from '../team-item';

const TEAMS = gql`
  query GetTeams {
    squad {
      data {
        id
        attributes {
          Team {
            title
            Members (pagination: { limit: -1 }){
              id
              fullname
              role
              linkedin
              body
              picture {
                data {
                  id
                  attributes {
                    url
                    alternativeText
                    caption
                    width
                    height
                  }
                }
              }
            }
          }
          createdAt
          updatedAt
          publishedAt
        }
      }
    }
  }
`

const Team = () => {
  const { loading, error, data } = useQuery(TEAMS);
    
  if(error){
    console.log('Team: ', error);
  }

  if(loading){
    return <p>Cargando Teams...</p>
  }

  return (
    <>
      {data?.squad?.data?.attributes?.Team?.map((team, index) => (
        <div key={`team${index}`} className="l-about" id={index > 0 ? `ourTeam${team.id}` : "ourTeam"}>
          {team.title && (
            <div className="l-about__header">
              <h3 className="l-about__title">
                {team.title}
              </h3>
            </div>
          )}
          <div className="l-about__body">
            <div className="c-profile">
              {team.Members?.map(member => (
                <TeamItem
                  id={member.id}
                  key={`member${member.id}`} 
                  fullname={member.fullname} 
                  role={member.role}
                  linkedin={member.linkedin}
                  picture={member.picture} 
                />
              ))}
            </div>
            {team.Members?.map(member => {
              if(member.body == null) return '';
              return(
                <Modal
                  id={member.id}
                  key={`modal${member.id}`} 
                  fullname={member.fullname} 
                  body={member.body} 
                />
              )
            })}
          </div>
        </div>
      ))}
    </>
  )
}

export default Team