import handleStrapiUrl from '../../../lib/handle-strapi-url';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useQuery, gql } from '@apollo/client';
import check_svg from '../../../assets/images/check.svg';
import tab_energy_svg from '../../../assets/images/tab-energy.svg';
import tab_gam_svg from '../../../assets/images/tab-gam.svg';
import tab_inmobiliario_svg from '../../../assets/images/tab-inmobiliario.svg';
import tab_rent_svg from '../../../assets/images/tab-rent.svg';

const GOALS = gql`
    query GetGoals {
        goal {
            data {
                id
                attributes {
                    Tabs {
                        title
                        background {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                    caption
                                    width
                                    height
                                }
                            }
                        }
                        Item {
                            id
                            text
                        }
                    }
                createdAt
                updatedAt
                publishedAt
                }
            }
        }
    } 
`

const SectionGoals = () => {
    const { loading, error, data } = useQuery(GOALS);
    
    if(error){
        console.log('SectionGoals: ', error);
    }

    if(loading){
        return <p>Cargando SectionGoals...</p>
    }

    return (
        <section className="section-goals" id="section-goals">
            <div className="section-goals__number">
                <h3>1</h3>
            </div>
            <div className="section-goals__content">
                <div className="c-tabs-goals">
                    <div className="tab-content" id="myTabContent">
                        {data?.goal?.data?.attributes?.Tabs.map((tab, index) => (
                            <div className={index === 0 ? "tab-pane fade show active" : "tab-pane fade"} id={`goal-${index}-pane`} role="tabpanel" aria-labelledby={`goal-${index}-tab`}>
                                <div className="d-flex">
                                    <div className="c-tabs-goals__content">
                                        <h2 className="c-tabs-goals__title">
                                            {tab?.title}
                                        </h2>
                                        {tab?.Item.map(item => (
                                            <div className="c-tabs-goals__item">
                                                <img src={check_svg} alt="" className="c-tabs-goals__icon"/>
                                                <ReactMarkdown
                                                    className="c-tabs-goals__text"
                                                    children={item?.text}  
                                                    remarkPlugins={[remarkGfm]}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <img src={handleStrapiUrl(tab?.background?.data?.attributes?.url)} alt="" className="c-tabs-goals__image"/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {data?.goal?.data?.attributes?.Tabs.map((tab, index) => (
                            <li className="nav-item" role="presentation">
                                <button className={index === 0 ? "nav-link active" : "nav-link"} id={`goal-${index}-tab`} data-bs-toggle="tab" data-bs-target={`#goal-${index}-pane`} type="button" role="tab" aria-controls={`goal-${index}-pane`} aria-selected={index === 0 ? "true" : "false"}>
                                    {tab?.title}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default SectionGoals