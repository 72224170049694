import React from 'react';
import Slider from 'react-slick';
import SliderCenterItem from '../slider-center-item';
import { useQuery, gql } from '@apollo/client';

const MEMORIES = gql`
query GetMemories {
    memo {
      data {
        id
        attributes {
          Documents {
            id
            title
            subtitle
            docNumber
            date
            pdf {
              data {
                id
                attributes {
                  name
                  url
                  size
                  createdAt
                  updatedAt
                }
              }
            }
          }
        }
      }
    }
  }
`

const settings = {
    centerMode: true,
    className: "c-slider-doc",
    centerPadding: "60px",
    slidesToShow: 3,
    responsive: [
        {
            breakpoint: 900,
            settings: {
                arrows: true,
                centerMode: true,
                centerPadding: "20px",
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 768,
            settings: {
                arrows: true,
                centerMode: true,
                centerPadding: "10px",
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 700,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: "10px",
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: "10px",
                slidesToShow: 1,
            },
        },
    ],
};

const SliderCenter = () => {
    const { loading, error, data } = useQuery(MEMORIES);

    if(error){
        console.log('SliderCenter: ', error);
    };

    if(loading){
        return <p>Cargando Memorias...</p>
    }

    const handleSlidesToShow = (memoriesLength, slidesToShow) => {
        if(memoriesLength <= slidesToShow){
            if(memoriesLength - slidesToShow === 0){
                return slidesToShow - 1;
            }else{
                return 1;
            }
        }

        return slidesToShow;
    }

    if(data?.memo?.data.length > 0){
        const memoriesLength = data?.memo?.data.length;
        settings.slidesToShow = handleSlidesToShow(memoriesLength, settings.slidesToShow);
        settings.responsive.map(setting => setting.slidesToShow = handleSlidesToShow(memoriesLength, setting.slidesToShow))
    }

    return (
        <Slider {...settings}>
            {data?.memo?.data?.attributes?.Documents?.map(memory => (
                <SliderCenterItem 
                    id={memory.id}
                    key={`memory${memory.id}`}
                    title={memory?.title}
                    subtitle={memory?.subtitle}
                    docNumber={memory?.docNumber}
                    pdf={memory?.pdf?.data?.attributes}
                />
            ))} 
        </Slider>
    )
}

export default SliderCenter