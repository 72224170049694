import { useState, useRef } from 'react';
import handleStrapiUrl from '../../lib/handle-strapi-url';
import { useQuery, gql } from '@apollo/client';

const SLIDESHOW = gql`
    query GetModalWelcome {
        modalWelcome {
            data {
                id
                attributes {
                    carousel {
                        id
                        image {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                        youtube
                        image_link
                    }
                    createdAt
                    updatedAt
                    publishedAt
                }
            }
        }
    }
`

const ModalWelcolme = () => {
    const [carouselSlide, setCarouselSlide] = useState(1);
    const { loading, error, data } = useQuery(SLIDESHOW);
    const modalRef = useRef(null);

    if(error){
        console.log('SLIDESHOW: ', error);
    }

    if(loading){
        return <p>Cargando SLIDESHOW...</p>
    }
    
    const handleModalClose = () => {
        modalRef.current.classList.remove('show');
        modalRef.current.style = "display: none";
    }

    const handleYoutubeUrl = (url) => {
        return url.replace('watch?v=', 'embed/')
    }

    const handleArrowSlides = (arrow) => {
        if(data){
            const carouselSlidesLength = data?.modalWelcome?.data?.attributes?.carousel.length;
            if(arrow === 'prev'){
                if(carouselSlide === 1){
                    setCarouselSlide(carouselSlidesLength);
                }else{
                    const value = carouselSlide - 1;
                    setCarouselSlide(value);
                }
            }
            if(arrow === 'next'){
                if(carouselSlide === carouselSlidesLength){
                    setCarouselSlide(1);
                }else{
                    const value = carouselSlide + 1;
                    setCarouselSlide(value);
                }
            }  
        }     
    }

    return (
        <>
            {data?.modalWelcome?.data && (
                <div ref={modalRef} className="modal fade c-modal-welcome show" tabIndex="-1" id="modalWelcome" style={{display: "block"}}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div style={{zIndex: "1"}} className="c-modal-welcome__button" data-dismiss="modal" onClick={handleModalClose}>
                                <button 
                                    type="button" 
                                    className="btn-close btn-close-white" 
                                    aria-label="Close"
                                    
                                ></button>
                            </div>
                            <div className="modal-body ">
                                <div id="welcolme-carousel" className="carousel slide welcolme-carousel" data-bs-ride="carousel">
                                    <div className="carousel-indicators">
                                        {data?.modalWelcome?.data?.attributes?.carousel?.map((slide, index) => (
                                            <>
                                                {(slide.youtube || slide.image.data) && (<button key={`indicator${index}`} type="button" data-bs-target="#welcolme-carousel" data-bs-slide-to={`${index}`} className={index === 0 ? "active" : ""} aria-current={index === 0 ? "true" : "false"} aria-label={`slide${slide.id}`}></button>)}
                                            </>
                                        ))}
                                    </div>
                                    <div className="carousel-inner">
                                        {data?.modalWelcome?.data?.attributes?.carousel?.map((slide, index) => (
                                            <>
                                                {(slide.youtube || slide.image.data) && (
                                                    <div className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                                        {slide.youtube 
                                                            ? (<iframe key={`iframe${index}`} frameBorder="0" title="youtube video" width="100%" height="345" src={handleYoutubeUrl(slide.youtube)}></iframe>)
                                                            : 
                                                            (
                                                                <>
                                                                    {slide.image_link 
                                                                        ? 
                                                                        (
                                                                            <a href={slide.image_link} target="_blank" rel="noreferrer">
                                                                                <img key={`slide-img-${index}`} src={handleStrapiUrl(slide.image?.data?.attributes?.url)} className="d-block w-100" alt="..." /> 
                                                                            </a>
                                                                        ) 
                                                                        : 
                                                                        (
                                                                            <img key={`slide-img-${index}`} src={handleStrapiUrl(slide.image?.data?.attributes?.url)} className="d-block w-100" alt="..." />
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                    <div className="carousel-controls">
                                        <button onClick={() => handleArrowSlides('prev')} type="button" className={carouselSlide > 1 ? "carousel-control-prev active" : "carousel-control-prev"} data-bs-target="#welcolme-carousel" data-bs-slide="prev"> Previous</button>
                                        <span>{`${carouselSlide} de ${data?.modalWelcome?.data?.attributes?.carousel.length}`}</span>
                                        <button onClick={() => handleArrowSlides('next')} type="button" className={carouselSlide < data?.modalWelcome?.data?.attributes?.carousel.length ? "carousel-control-next active" : "carousel-control-next"} data-bs-target="#welcolme-carousel" data-bs-slide="next"> Next</button>                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>        
    )
}

export default ModalWelcolme;