import React from 'react'
import { Link } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';

const CORPORATIVELINKS = gql`
  query GetCorporativeLinks {
    corporatives(sort: "order") {
      data {
        id
        attributes {
          title
          createdAt
          updatedAt
        }
      }
    }
  }  
`

const CorporativeLinks = () => {
    const { loading, error, data } = useQuery(CORPORATIVELINKS);

    if(error){
        console.log('CorporativeLinks: ', error);
    };

    if(loading){
        return <p>Cargando CorporativeLinks...</p>
    }

    return (
        <>
            {data?.corporatives?.data?.map(corporative => (
                <li key={corporative.id}>
                <Link to={`/corporative/${corporative.id}`} className="dropdown-item">
                    {corporative.attributes.title}
                </Link>
                </li>
            ))}
        </>
    )
}

export default CorporativeLinks