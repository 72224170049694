import time_svg from '../../assets/images/time.svg';
import TimeLineItem from '../timeline-item';

const TimeLine = () => {
    let timelineBodyCard = null;
    let timelineHeaderYear = null;
    let currentIndex = 0;

    // const showTimeLine = (index) => {
    //     if (index < 0) {
    //         index = timelineBodyCard.length - 1;
    //     } else if (index >= timelineBodyCard.length) {
    //         index = 0;
    //     }
    
    //     timelineBodyCard.forEach((children) => {
    //         children.classList.remove("is-active");
    //     });

    //     timelineHeaderYear.forEach((children) => {
    //         children.classList.remove("is-active");
    //     });
    
    //     timelineBodyCard[index].classList.add("is-active");
    //     timelineHeaderYear[index].classList.add("is-active");
        
    // }

    // const hideSlide = (index) => {
    //     timelineHeaderYear[index].style.animation = "slided 1s both";
    // }

    // const showSlide = (index) => {
    //     timelineHeaderYear[index].style.animation = "none";
    // }

    // const next = () => {
    //     hideSlide(currentIndex)
    //     currentIndex++;
    //     showTimeLine(currentIndex);
    //     if (currentIndex === timelineBodyCard.length) {
    //         currentIndex = 0;
    //     }
    //     showSlide(currentIndex)
    // }

    // const prev = () => {
    //     hideSlide(currentIndex)
    //     currentIndex--;
    //     showTimeLine(currentIndex);
    //     if (currentIndex < 0) {
    //         currentIndex = timelineBodyCard.length - 1;
    //     }
    //     showSlide(currentIndex)
    // }

    const prev = () => {
        if(currentIndex > 0){
            timelineBodyCard[currentIndex].classList.remove('is-active')
            timelineHeaderYear[currentIndex].classList.remove("is-active");
            currentIndex--;
            updateSlider();
        }
    }

    const next = () => {
        if(currentIndex < timelineBodyCard.length - 1) {
            timelineBodyCard[currentIndex].classList.remove('is-active')
            timelineHeaderYear[currentIndex].classList.remove("is-active");
            currentIndex++;
            updateSlider();
        }
    }
    const updateSlider = () => {
        const translateValue = `-${currentIndex * 170}%`;
        timelineHeaderYear.forEach((children, index) => {
            
           if(index === currentIndex){
            children.style.transform = `translateX(${translateValue}) scale(1.8)`
           }else{
            children.style.transform = `translateX(${translateValue})`;
           }
        });
        timelineBodyCard[currentIndex].classList.add('is-active');
        timelineHeaderYear[currentIndex].classList.add("is-active");
    }
    
    const init = () => {
        timelineBodyCard = document.querySelectorAll(".c-timeline-year__card");
        timelineHeaderYear = document.querySelectorAll(".c-timeline-year__year")
        updateSlider(currentIndex);
    }

    return (
        <div className="l-about" id="timeLine">
            <div className="l-about__header">
                <img src={time_svg} alt="" className="l-about__icon" />
                <h3 className="l-about__title">Línea de tiempo</h3>
            </div>
            <div className="l-about__body respond">
                <TimeLineItem next={next} prev={prev} init={init}/>
            </div>
        </div>
    )
}

export default TimeLine