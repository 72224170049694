import React from 'react';
import plant_svg from '../../../assets/images/plant.svg';
import co2_svg from '../../../assets/images/co2.svg';

const SectionMilestone = ({props}) => {
    if(props != null){
        return (
            <section className="section-milestones" id="milestones">
                <h2 className="section-milestones__title">Impacto del fondo</h2>
                <div className="section-milestones__body">
                    <div className="c-card-green">
                    <div className="c-card-green__item">
                        <div className="c-card-green__icon">
                        <img src={plant_svg} alt=""/>
                        </div>
                        <div className="c-card-green__content">
                        <div>Más de</div>
                        <div>{props.LeftText}</div>
                        <div>Instalados y en desarrollo de energía renovable.</div>
                        </div>
                    </div>
                    <div className="c-card-green__item">
                        <div className="c-card-green__icon">
                        <img src={co2_svg} alt=""/>
                        </div>
                        <div className="c-card-green__content">
                        <div>Reducir en</div>
                        <div>{props.RightText} <span>toneladas</span></div>
                        <div>Las emisiones <b>CO2/año.</b></div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        )
    }  
    
}

export default SectionMilestone