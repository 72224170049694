import React from 'react'
import Header from '../../components/header';
import Footer from '../../components/footer';
import AccordionItem from '../../components/accordion-item';

const Faq = () => {
  return (
    <>
      <Header background={"c-header--white"}/>
      <main className="l-faq no-bg">
        <div className="l-faq__header">
            <div className="container">
                <div className="col-lg-12">
                    <h2 className="l-faq__title">Fondos de inversión</h2>
                </div>
            </div>
        </div>
        <div className="l-faq__body pb-5 mb-5">
            <div className="container">
                <div className="accordion c-accordion" id="faqAccordion">
                    <AccordionItem/>
                </div>
            </div> 
        </div>
      </main>
      <Footer/>
    </>
  )
}

export default Faq