import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ModalContact from '../../components/modal-contact';
import ModalContactSuccess from '../../components/modal-contact-success';
import clock_svg from '../../assets/images/clock.svg';
import pointer_svg from '../../assets/images/pointer.svg';
import mail_svg from '../../assets/images/mail.svg';

const Contact = () => {
    
    return (
        <>
            <Header background={"c-header--white"}/>
            <div className="l-contact">
                <div className="l-contact__header">
                    ¡Gracias por ponerte en contacto con nosotros! 
                </div>
                <div className="l-contact__body">
                    <div className="l-contact__info">
                        <h2 className="l-contact__title">Contactos</h2>
                        <div className="l-contact__item">
                            <img src={clock_svg} alt=""/>
                            <p>Horario de lunes a viernes de 8:30 a.m. a 6:00 p.m. </p>
                        </div>
                        <div className="l-contact__item">
                            <img src={pointer_svg} alt=""/>
                            <div>
                                <p>Torre Lincoln Mil57, Av. Abraham Lincoln No. 1057, 3r Piso</p>
                                <a className="d-block" href="#">Ver en el mapa <span className="icon-external-link"></span></a>
                            </div>
                        </div>
                        <div className="l-contact__item">
                            <img src={mail_svg} alt=""/>
                            <a href = "mailto:info@altiosafi.com" className="u-primary-color u-size-normal u-no-underline">info@altiosafi.com</a>
                        </div>
                        <button className="c-btn c-btn--primary mt-5 ml-2" data-bs-toggle="modal" data-bs-target="#modalContact">Contáctanos</button>
                    </div>
                    <div className="l-contact__map">
                        <iframe
                            title='google maps iframe' 
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15136.338836435083!2d-69.9378856!3d18.4798218!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xab513d62ff0f1e74!2sTorre%20Lincoln%201057!5e0!3m2!1ses!2sdo!4v1664809895330!5m2!1ses!2sdo"
                            style={{border: 0}} 
                            allowFullScreen={false} 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
            <ModalContact/>
            <ModalContactSuccess/>
            <Footer/>
        </>
    )
}

export default Contact