import React, {useState} from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { phoneRegExp } from '../../lib/regex';
const POST_WORK = gql`
    mutation createWork(
        $firstName: String!
        $lastName: String!
        $email: String!
        $phone: String!
        $english: String!
        $degree: String!
        $career: String!
        $master: String!
    ) {
        createWork(
            data: {
                firstname: $firstName
                lastname: $lastName
                email: $email
                phone: $phone
                english: $english
                degree: $degree
                career: $career
                master: $master
            }
        ) {
            data {
                id
                attributes {
                    firstname
                    lastname
                    email
                    phone
                    english
                    degree
                    career
                    master
                }
            }
        }
    }      
`;

const MAX_PDF_SIZE = 10485760; // 10485760bytes = 10mb.
const WorkWithUsFormValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Nombre muy corto!')
        .max(50, 'Nombre muy largo!')
        .required('El campo es requerido.'),
    lastName: Yup.string()
        .min(2, 'Apellido muy corto!')
        .max(50, 'Apellido muy largo!')
        .required('El campo es requerido.'),
    email: Yup.string()
        .email('Correo electrónico inválido.')
        .required('El campo es requerido.'),
    phone: Yup.string()
        .matches(phoneRegExp, 'El campo teléfono es inválido')
        .required('El campo es requerido.'),
    english: Yup.string()
        .oneOf(["Básico", "Medio", "Avanzado"])
        .label("Seleccione una opción")
        .required('El campo es requerido.'),
    degree: Yup.string()
        .label("Seleccione una opción")
        .oneOf(["Bachiller", "Licenciatura", "Maestría", "Postgrado", "Doctorado"])
        .required('El campo es requerido.'),
    career: Yup.string()
        .min(2, 'Carrera muy corto!')
        .required('El campo es requerido.'),
    master: Yup.string()
        .min(2, 'Especialidad muy corto!'),
    resume: Yup.mixed()
        .required("El campo es requerido.")
        .test("type", "Solo se aceptan archivos pdf", (value) => {
            return value && (
                value.type === 'application/pdf'
            );
        })
        .test("fileSize", `Solo se aceptan archivos menor a 10mb`, (value) => {
            return value && value.size <= MAX_PDF_SIZE;
        })
});

const initialWorkValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    english: '',
    degree: '',
    career: '',
    master: '',
    resume: ''
}

export const WorkWithUsForm = () => {
    const [createWork, { data, error, reset }] = useMutation(POST_WORK);
    const [form, setForm] = useState(null);
    const [pdfAttachment, setPdfAttachment] = useState(false);
    const [formActions, setFormActions] = useState(null);

    const handleFormSubmit = (formData, actions) => {
        console.log('handleFormSubmit [formData]', formData);
        setForm(formData);
        setFormActions(actions);
        // uploadResume(form, data, actions);
        // toogleModals(form);
    }
    
    const uploadResume = async (afterCreate) => {
        console.log('uploadResume')
        console.log('afterCreate: ', afterCreate);
        const formData = new FormData() // pure javascript nothing to do with react
        formData.append('files', form.resume)
        // formData.append('ref', 'events') //'ref' The collection we want to use
        formData.append('ref', 'api::work.work')
        formData.append('refId', afterCreate.data.id) //'refId' The event Id
        formData.append('field', 'resume') //'field' the image field we called 'image'

        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/upload`, {
            method: 'POST',
            body: formData
        })

        if (res.ok) {
            console.log('res.ok')
            console.log('res', res)
            toogleModals();
        }

        if(res.error){
            console.error('uploadResume', res.error);
        }
    }

    const toogleModals = () => {
        const getModalSuccessTitle = document.getElementsByClassName('c-modal-success__title');
        getModalSuccessTitle[0].children[0].innerHTML = `${form?.firstName} ${form?.lastName}`;
        const getModalSuccess = document.getElementById("modalSuccess");
        getModalSuccess.classList.add('show');
        getModalSuccess.style = "display: block";
        clearFormData();
    }

    const clearFormData = () => {
        reset();
        formActions.resetForm(initialWorkValues);
        setForm(null);
        setFormActions(null);
        setPdfAttachment(false);
    }

    if (error) console.log('WorkWithUsForm : error', error.message)

    if(data){
        if(data.createWork) uploadResume(data.createWork)
    }

    return (
        <div className="l-work">
            <Formik
                initialValues={initialWorkValues}
                validationSchema={WorkWithUsFormValidationSchema}
                onSubmit={async (values, actions) => {
                    const formData = await values;
                    console.log('onSubmit [formData]:', formData)
                    //handleFormSubmit(formData, actions);
                    await createWork({ variables: { 
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        email: formData.email,
                        phone: formData.phone,
                        english: formData.english,
                        degree: formData.degree,
                        career: formData.career,
                        master: formData.master,
                    }}).then(
                        handleFormSubmit(formData, actions)
                    )
                    
                }}
            >
                {({ errors, touched, setFieldValue, isSubmitting }) => (
                    <Form>
                        <div className="l-work__header">
                            <h2 className="l-work__title">Trabaja con nosotros</h2>
                            <div className="align-items-center d-flex u-gap-1 flex-wrap">
                                <p className="mb-0">¿Completaste toda la información?</p> 
                                <button
                                    type="submit" 
                                    className="c-btn c-btn--primary"
                                    
                                >
                                    
                                    Enviar solicitud
                                </button>
                            </div>
                        </div>
                        <div className="l-work__body">
                            <div className="c-form-work">
                                <h3 className="c-form-work__title">Formulario de solicitud </h3>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="c-form-group">
                                            <label className="c-form-group__label">Nombre</label>
                                            <Field name="firstName" className="c-form-group__input" type="text" placeholder="Ingresa tu nombre aquí"/>
                                            {errors.firstName && touched.firstName && <p>{errors.firstName}</p>}
                                        </div>
                                        <div className="c-form-group">
                                            <label className="c-form-group__label">Apellido</label>
                                            <Field name="lastName" className="c-form-group__input" type="text" placeholder="Ingresa tu apellido aquí"/>
                                            {errors.lastName && touched.lastName && <p>{errors.lastName}</p>}
                                        </div>
                                        <div className="c-form-group">
                                            <label className="c-form-group__label">Email</label>
                                            <Field name="email" className="c-form-group__input" type="email" placeholder="Ingresa tu correo aquí"/>
                                            {errors.email && touched.email && <p>{errors.email}</p>}
                                        </div>
                                        <div className="c-form-group">
                                            <label className="c-form-group__label">Teléfono</label>
                                            <Field name="phone" className="c-form-group__input" type="text" placeholder="Ingresa tu número de teléfono aquí"/>
                                            {errors.phone && touched.phone && <p>{errors.phone}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="c-form-group">
                                            <label className="c-form-group__label">Nivel de inglés</label>
                                            <Field name="english" className="c-form-group__input" as="select">
                                                <option value="" disabled defaultValue hidden>Seleccione una opción</option>
                                                <option value="Básico">Básico</option>
                                                <option value="Medio">Medio</option>
                                                <option value="Avanzado">Avanzado</option>
                                            </Field>
                                            {errors.english && touched.english && <p>{errors.english}</p>}
                                        </div>
                                        <div className="c-form-group">
                                            <label className="c-form-group__label">Nivel académico</label>
                                            <Field name="degree" className="c-form-group__input" as="select">
                                                <option value="" disabled defaultValue hidden>Seleccione una opción</option>
                                                <option value="Bachiller">Bachiller</option>
                                                <option value="Licenciatura">Licenciatura</option>
                                                <option value="Maestría">Maestría</option>
                                                <option value="Postgrado">Postgrado</option>
                                                <option value="Doctorado">Doctorado</option>
                                            </Field>
                                            {errors.degree && touched.degree && <p>{errors.degree}</p>}
                                        </div>
                                        <div className="c-form-group">
                                            <label className="c-form-group__label">Carrera</label>
                                            <Field name="career" className="c-form-group__input" type="text" placeholder="Ingresa la carrera aquí"/>
                                            {errors.career && touched.career && <p>{errors.career}</p>}
                                        </div>
                                        <div className="c-form-group">
                                            <label className="c-form-group__label">¿Algún postgrado ó maestría?</label>
                                            <Field name="master" className="c-form-group__input" type="text" placeholder="Ingresar opcional"/>
                                            {errors.master && touched.master && <p>{errors.master}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="c-form-file">
                                            <div className="c-form-file__title">Adjuntar Hoja de vida ó resume</div>
                                            <div className="c-form-file__input">
                                                <input 
                                                    name="resume" 
                                                    type="file" 
                                                    onChange={(event) => {
                                                        setFieldValue("resume", event.currentTarget.files[0]);
                                                        setPdfAttachment(true)
                                                    }} 
                                                />

                                                <span className="icon-upload"></span>
                                            </div>
                                            {pdfAttachment === true && (<div className="c-form-file__subtitle">Documento anclado sastifactoriamente</div>) }
                                            {errors.resume && touched.resume && <div>{errors.resume}</div>}
                                            <div className="c-form-file__title">Presiona para adjuntar resume</div>
                                            <div className="c-form-file__subtitle">Tamaño máximo 10 Mb</div>
                                            <div className="c-form-file__subtitle">Formato permitido PDF</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>   
                )}
            </Formik>
        </div>
    )
}

export default WorkWithUsForm
