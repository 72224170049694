import {useState, useEffect} from 'react';
import OpenFunds from '../../components/open-funds'
import Header from '../../components/header';
import Footer from '../../components/footer';
import ModalContact from '../../components/modal-contact';
import ModalContactSuccess from '../../components/modal-contact-success';
import parval_png from '../../assets/images/parval.png';
import alpha_png from '../../assets/images/alpha.png';
import united_capital_png from '../../assets/images/united-capital.png';

const HowTo = () => {
    const [tabActive, setTabActive] = useState('open');
    
    useEffect(() => {
        const getTabActive = sessionStorage.getItem('howInvestTab');
        setTabActive(getTabActive);
    }, []);
    return (
        <>
            <Header background={"c-header--white"}  />
            <main className="l-faq no-bg">
                <div className="l-faq__header">
                    <div className="container">
                        <div className="col-lg-12">
                            <h2 className="l-faq__title">¿Cómo invertir?</h2>
                        </div>
                    </div>
                </div>
                <div className="l-faq__body pb-5 mb-5">
                    <div className="container">
                        <div className="c-tabs-faq">
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button className={tabActive === 'open' ? "nav-link active" : "nav-link"} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Fondos Abiertos</button>
                                    <button className={tabActive === 'close' ? "nav-link active" : "nav-link"} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Fondos Cerrados</button>
                                </div>
                            </nav>
                            {/* "tab-pane fade show active" */}
                            <div className="tab-content" id="nav-tabContent">
                                <div className={tabActive === 'open' ? "tab-pane fade show active" : "tab-pane fade"} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex="0">
                                    <div className="d-flex justify-content-between u-gap-1 mb-5 flex-wrap">
                                        <p className="mb-5 pb-1 "><b>Para invertir en nuestros fondos abiertos</b> debes seguir los siguientes pasos:</p>
                                        <div className="d-flex u-gap-2 u-font-bold ">
                                            <a href="#" className="u-no-underline u-link-color">Ver requisitos</a>
                                            <a href="#" className="u-no-underline u-link-color">Llenar formulario</a>
                                        </div>
                                    </div>
                                    <div className="accordion c-accordion-how">
                                        <OpenFunds />
                                    </div>
                                </div>
                                <div className={tabActive === 'close' ? "tab-pane fade show active" : "tab-pane fade"} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex="0">
                                    <div className="mt-lg-5 mt-md-1 pt-5 row">
                                        <div className="col-lg-7 mt-lg-4">
                                            <h2 className="mb-2 u-font-bold">Para invertir en nuestros fondos cerrados debes dirigirte a:</h2>
                                            <p><b>Parallax Valores</b>, S.A. <b>(Parval) Puesto de Bolsa, Alpha Inversiones, S.A. Puesto de Bolsa, United Capital</b> Puesto de Bolsa o cualquier puesto
                                                de bolsa autorizado por la Superintendencia del Mercado de Valores e inscrito en la Bolsa de Valores de la República Dominicana. </p>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="row">
                                                <div className="col-md-4 align-items-center d-flex flex-column justify-content-end mb-sm-5 mt-sm-5 mb-md-0 mt-md-0">
                                                    <img src={parval_png} alt="parval logo"/>
                                                    <a className="mt-3 u-link-color u-no-underline" href="https://www.parval.com.do/">Ir a página <span className="ml-1 icon-external-link"></span></a>
                                                </div>
                                                <div className="col-md-4 align-items-center d-flex flex-column justify-content-end mb-sm-3 mb-md-0 mt-md-0">
                                                    <img src={alpha_png} alt=""/>
                                                    <a className="mt-3 u-link-color u-no-underline" href="https://alpha.com.do/">Ir a página <span className="ml-1 icon-external-link"></span></a>
                                                </div>
                                                <div className="col-md-4 align-items-center d-flex flex-column justify-content-end">
                                                    <img src={united_capital_png} alt=""/>
                                                    <a className="mt-3 u-link-color u-no-underline" href="https://www.unitedcapitaldr.com/">Ir a página <span className="ml-1 icon-external-link"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ModalContact/>
            <ModalContactSuccess/>
            <Footer/>
        </>
    )
}

export default HowTo