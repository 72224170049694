import {useEffect} from 'react';
import handleStrapiUrl from '../../lib/handle-strapi-url';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useQuery, gql } from '@apollo/client';
import altio_year_svg from '../../assets/images/altio-year.svg';

const TIMELINES = gql`
    query GetTimelines {
        timelines(sort: "date") {
            data {
                id
                attributes {
                    title
                    body
                    date
                    image{
                        data{
                          attributes{
                            url
                          }
                        }
                    }
                    isTimelineSmall
                    createdAt
                    updatedAt
                    publishedAt
                }
            }
        }
    }
`

const TimeLineItem = ({next, prev, init}) => {
    const cropTimeLineDate = (date) => {
        const dateCropped = date.substring(0, 4);
        return dateCropped;
    }
    
    const { loading, error, data } = useQuery(TIMELINES);
    
    useEffect(() => {
        if(data?.timelines?.data) init()
        
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    if(error){
        console.log('TimeLineItem: ', error);
    };

    if(loading){
        return <p>Cargando Timelines...</p>
    }

    
    return (
        <div className="c-timeline-year">
            <div className="c-timeline-year__header">
                {data?.timelines?.data?.map((timeline, index) => (
                    <div key={`timelineYear${index}`} className="c-timeline-year__year">
                    <span>{cropTimeLineDate(timeline?.attributes?.date)}</span>
                        <div>
                            <img src={altio_year_svg} alt=""/>
                        </div>
                    </div>
                ))}
            </div>
            <div className="c-timeline-year__body">
                {data?.timelines?.data?.map((timeline, index) => (
                    <div key={`timelineCard${index}`} className="c-timeline-year__card">
                        <div className="c-timeline-year__card__image">
                            <img src={handleStrapiUrl(timeline?.attributes?.image?.data?.attributes?.url)} alt=""/>
                        </div>
                        <div className="c-timeline-year__card__content">
                            <h4>{timeline?.attributes?.title} </h4>
                            <ReactMarkdown 
                                children={timeline?.attributes?.body}  
                                remarkPlugins={[remarkGfm]}
                            />
                        </div>
                    </div>
                ))}                                                        
            </div>
            <div className="c-timeline-year__footer">
                <button id="prevButton" className="c-timeline__button btn-left" onClick={prev}>&larr;</button>
                <button id="nextButton" className="c-timeline__button btn-right" onClick={next}>&rarr;</button>
            </div>
        </div>
    )
}

export default TimeLineItem