import dayjs from "dayjs";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.locale(es);
dayjs.extend(relativeTime);

const timeFromNow = (time) => {
  return dayjs(time).fromNow();
};

const internationalFormat = (time) => {
  return dayjs(time).format('DD/MM/YYYY');
}

export {
  timeFromNow,
  internationalFormat
};