import React from 'react'
import modal_check_svg from '../../assets/images/modal-check.svg';

const ModalComplaintSuccess = () => {
    return (
        <div
            className="modal fade c-modal-success"
            id="modalSuccess"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="c-modal-success__icon">
                            <img src={modal_check_svg} alt=""/>
                        </div>
                        <h3 className="c-modal-success__title">
                            <b>¡Mensaje enviado!</b>
                        </h3>
                        <p className="c-modal-success__text">
                            Nuestro equipo estará revisando la información suministrada
                        </p>
                        <div className="c-modal-success__footer mt-auto">
                            <button
                                type="button"
                                className="c-btn c-btn--white"
                                data-bs-dismiss="modal"
                            >
                                Listo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalComplaintSuccess