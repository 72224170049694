import React from 'react';
import handleStrapiUrl from '../../lib/handle-strapi-url';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Header from '../../components/header';
import Footer from '../../components/footer';
import AccordionDocumentItem from '../../components/accordion-document-item';
import { useQuery, gql } from '@apollo/client';

const CORPORATIVE_BY_ID = gql`
    query GetCorporative($id: ID!) {
        corporative(id: $id) {
            data {
                id
                attributes {
                    title
                    body
                    icon {
                        data {
                            id
                            attributes {
                                url
                                size
                                createdAt
                                updatedAt
                            }
                        }
                    }
                    Documents {
                        id
                        title
                        Files {
                            id
                            name
                            pdf {
                                data {
                                    id
                                    attributes {
                                        url
                                        updatedAt
                                        createdAt
                                    }
                                }
                            }
                        }
                    }
                    createdAt
                    updatedAt
                    publishedAt
                }
            }
        }
    }
`

const Corporative = () => {
    const { id } = useParams();

    const { loading, error, data } = useQuery(CORPORATIVE_BY_ID, {
        variables: { id: id }
    });

    if(error){
        console.log('Corporative: ', error);
    };

    if(loading){
        return <p>Cargando Corporative...</p>
    }

    return (
        <>
            <Header background={"c-header--white"}/>
            <div className="l-corporative">
                <div className="l-corporative__image">
                    <img src={handleStrapiUrl(data?.corporative?.data.attributes.icon.data.attributes.url)} alt=""/>
                </div>
                <div className="l-corporative__content">
                    <h2 className="u-big-size u-font-bold mb-5 mt-5 text-center">
                        {data?.corporative?.data.attributes?.title}
                    </h2>
                    {data?.corporative?.data.attributes?.body && (
                        <div className="l-corporative__paragraph mb-5 pb-5">
                            <ReactMarkdown 
                                children={data?.corporative?.data.attributes?.body}  
                                remarkPlugins={[remarkGfm]}
                            />
                        </div>
                    )}
                    {data?.corporative?.data.attributes?.Documents?.length > 0 && (
                        <>
                            <p className="text-center u-text-color"><b>Presiona sobre cada uno</b> para ver y descargar los elementos</p>
                            <div className="accordion c-accordion-document" id="accordionDocument">
                                {data?.corporative?.data?.attributes.Documents?.map(document => (
                                    <AccordionDocumentItem 
                                        key={`AccordionDocumentItem-${document.id}`}
                                        id={document.id}
                                        title={document.title}
                                        quantity={document.Files.length} 
                                        files={document.Files}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Corporative