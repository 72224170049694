import React from 'react';
import { timeFromNow, internationalFormat } from '../../lib/times-formats';
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import handleStrapiUrl from '../../lib/handle-strapi-url';

const Card = ({
    id,
    title,
    body,
    date,
    publishedAt, 
    thumbnail
}) => {
    const formatPublishedAt = (publishedAt, title) => {
        const publishedAtTime = new Date(publishedAt).getTime();
        const now = new Date().getTime();
        const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000; // 30 days in ms
        const timeDiffInMs = now - publishedAtTime; // time difference from now until publishedAt

        //Is older than 30 days
        if(timeDiffInMs >= thirtyDaysInMs){
            return internationalFormat(publishedAt);
        }
        return timeFromNow(publishedAt);
    }
    return (
        <div className="c-card-news">
            <div className="c-card-news__image">
                <img src={handleStrapiUrl(thumbnail)} alt=""/>
                {date ? (
                    <div className="c-card-news__date">
                        {internationalFormat(date)} <span className="icon-calendar ml-1"></span>
                    </div>
                ) : (
                    <div className="c-card-news__date">
                        {formatPublishedAt(publishedAt, title)} <span className="icon-calendar ml-1"></span>
                    </div>
                )}
            </div>
            <h4 className="c-card-news__title">
                {title}
            </h4>
            <ReactMarkdown
                className="c-card-news__text" 
                children={body}  
                remarkPlugins={[remarkGfm]}
            />
            <Link to={`/news-details/${id}`} className="c-btn c-btn--outline w-100 mt-1" >
                Ver más
            </Link>
        </div>
    )
}

export default Card