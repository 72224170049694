import React from 'react';
import handleStrapiUrl from '../../lib/handle-strapi-url';
import profile_icon_svg from '../../assets/images/profile-icon.svg';

const TeamItem = ({
    id,
    fullname,
    role,
    linkedin,
    picture
}) => {
    return (
        <div className="c-profile__item">
            <div className="c-profile__image">
                <img
                    src={handleStrapiUrl(picture.data?.attributes?.url)}
                    alt=""
                    data-bs-toggle="modal"
                    data-bs-target={`#modal${id}`}
                />
                <a
                    target="_blank"
                    href={linkedin ? linkedin : "#"}
                    className="c-profile__icon"
                >
                    <img src={profile_icon_svg} alt="" />
                </a>
            </div>
            <div className="c-profile__name">
                {fullname}
            </div>
            <div className="c-profile__role">
                {role}
            </div>
        </div>
    )
}

export default TeamItem