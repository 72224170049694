import handleStrapiUrl from '../../lib/handle-strapi-url';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useQuery, gql } from '@apollo/client';

const OPENFUNDS = gql`
    query GetOpenFunds {
        openFund {
            data {
                attributes {
                    OpenFunds {
                        id
                        title
                        subtitle
                        icon{
                            data{
                                attributes{
                                    url
                                }
                            }
                        }
                        body
                    }
                }
            }
        }
    }
`

const OpenFunds = () => {
    const { loading, error, data } = useQuery(OPENFUNDS);
    let openFunds = []

    if(error){
        console.log('Openfunds: ', error);
    }

    if(loading){
        return <p>Cargando Openfunds...</p>
    }

    if(!loading){
        if(data){
            if(data.openFund.data.attributes.OpenFunds){
                openFunds = [...data.openFund.data.attributes.OpenFunds]
            }
        }
    }
    
    return (
        <div className="accordion c-accordion-how">
            {openFunds ? openFunds.map((item, index) => (
                <div key={`accordion-item${item.id}`} className="accordion-item">
                    <h2 className="accordion-header" id={`heading${item.id}`}>
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${item.id}`} aria-expanded="true" aria-controls={`collapse${item.id}`}>
                        <div className="c-accordion-how__icon"><img src={handleStrapiUrl(item.icon.data.attributes.url)} alt="accordion icon"/></div>
                        <div className="flex-1">
                            <h3 className="c-accordion-how__title">{item.title}</h3>
                            <p className="c-accordion-how__subtitle">{item.subtitle}</p>
                        </div>
                        <span className="c-accordion-how__number">Paso {index + 1}</span>
                    </button>
                    </h2>
                    <div id={`collapse`+item.id} className="accordion-collapse collapse" aria-labelledby={`heading${item.id}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <ReactMarkdown
                                className="u-font-bold u-primary-color" 
                                children={item.body}  
                                remarkPlugins={[remarkGfm]}
                            />
                        </div>
                    </div>
                </div>
            )) : <p>No hay datos</p>}
        </div>
    )
}

export default OpenFunds