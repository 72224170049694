import React from 'react'

const SectionBanner = () => {
  return (
    <section className="section-banner">
      <h3 className="section-banner__title">
        Siempre a la vanguardia de la innovación, 
        manteniéndonos como <b>líderes</b> en <b>nuestras categorías…</b>
      </h3>
      <a href="#section-goals" className="c-btn-scrollDown"> 
        <span className="icon-arrow-down"></span>
      </a>
    </section>
  )
}

export default SectionBanner