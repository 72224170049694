import React, {useState, useRef} from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';

const POST_CLAIM = gql`
    mutation createClaim(
        $firstName: String!
        $lastName: String!
        $email: String!
        $phone: String!
        $message: String!
    ) {
        createClaim(
            data: {
                firstname: $firstName
                lastname: $lastName
                email: $email
                phone: $phone
                message: $message
            }
        ) {
            data {
                id
                attributes {
                    firstname
                    lastname
                    email
                    phone
                    message
                }
            }
        }
    }    
`;

const stepOneFormValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Nombre muy corto!')
        .max(50, 'Nombre muy largo!')
        .required('El campo es requerido.'),
    lastName: Yup.string()
        .min(2, 'Apellido muy corto!')
        .max(50, 'Apellido muy largo!')
        .required('El campo es requerido.'),
    email: Yup.string()
        .email('Correo electrónico inválido.')
        .required('El campo es requerido.'),
    phone: Yup.string()
        .min(2, 'Teléfono muy corto!')
        .required('El campo es requerido.'),
});

const stepOneFormInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
}

const stepTwoFormValidationSchema = Yup.object().shape({
    message: Yup.string()
        .min(2, 'Mensaje muy corto!')
        .required('El campo es requerido.')
});

const stepTwoFormInitialValues = {
    message: '',
}

const ClaimForm = () => {
    const [step, setStep] = useState(1);
    const [stepOneFormData, setStepOneFormData] = useState(null);
    const [stepOneFormActions, setStepOneFormActions] = useState(null);
    const stepOneTab = useRef(null);
    const stepTwoTab = useRef(null);
    const tabPaneOne = useRef(null);
    const tabPaneTwo = useRef(null);

    const clearRestOfForms = () => {
        stepOneFormActions.resetForm(stepOneFormInitialValues);
    }
    const hideSteps = () => {
        stepOneTab.current.classList.remove("active");
        stepTwoTab.current.classList.remove("active");

        tabPaneOne.current.classList.remove("active");
        tabPaneTwo.current.classList.remove("active");

        tabPaneOne.current.classList.remove("show");
        tabPaneTwo.current.classList.remove("show");
    }
    const showStep = (tab, tabpane) => {
        tab.current.classList.add("active");
        tabpane.current.classList.add("active");
        tabpane.current.classList.add("show");
    }
    const handleStepOneFormSubmit = (data, actions) => {
        setStepOneFormData(data);
        setStepOneFormActions(actions);
        continueToStepTwo();
    }
    const continueToStepTwo = () => {
        setStep(2);
        hideSteps();
        showStep(stepTwoTab, tabPaneTwo);
    }
    const backToStepOne = () => {
        setStep(1);
        hideSteps();
        showStep(stepOneTab, tabPaneOne);
    }

    const [createClaim, { loading, error }] = useMutation(POST_CLAIM);

    if (loading) return <p>Enviando información del formulario</p>

    if (error) console.log('ModalContactForm : error', error.message)

    return (
        <div className="c-stepper">
            <div className="c-stepper__header">
                <div className="c-stepper__title">Formulario de reclamación</div>
                <div className="c-stepper__subtitle">Paso {step} de 2</div>
            </div>
            <div className="c-stepper__body">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button ref={stepOneTab} className="nav-link active" id="home"  type="button" role="tab" aria-selected="true">Datos personales <span className="icon-arrow-left"></span></button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button ref={stepTwoTab} className="nav-link" id="profile"  type="button" role="tab"  aria-selected="false">Tu reclamación<span className="icon-arrow-left"></span></button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div ref={tabPaneOne} className="tab-pane fade show active" id="home-pane">
                        <Formik
                            initialValues={stepOneFormInitialValues}
                            validationSchema={stepOneFormValidationSchema}
                            onSubmit={async (values, actions) => {
                                const data = await values;
                                handleStepOneFormSubmit(data, actions);
                                console.log('StepOne formData: ', data);
                            }}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <div className="c-form-group c-form-group--dark">
                                        <label className="c-form-group__label">Nombre</label>
                                        <Field name="firstName" className="c-form-group__input" type="text" placeholder="Ingresa tu nombre aquí"/>
                                        {errors.firstName && touched.firstName && <div>{errors.firstName}</div>}
                                    </div>
                                    <div className="c-form-group c-form-group--dark">
                                        <label className="c-form-group__label">Apellido</label>
                                        <Field name="lastName" className="c-form-group__input" type="text" placeholder="Ingresa tu primer apellido"/>
                                        {errors.lastName && touched.lastName && <div>{errors.lastName}</div>}
                                    </div>
                                    <div className="c-form-group c-form-group--dark">
                                        <label className="c-form-group__label">Email</label>
                                        <Field name="email" className="c-form-group__input" type="email" placeholder="Tu correo más utilizado"/>
                                        {errors.email && touched.email && <div>{errors.email}</div>}
                                    </div>
                                    <div className="c-form-group c-form-group--dark">
                                        <label className="c-form-group__label">Teléfono</label>
                                        <Field name="phone" className="c-form-group__input" type="tel" placeholder="Ingresa tu teléfono celular"/>
                                        {errors.phone && touched.phone && <div>{errors.phone}</div>}
                                    </div>
                                    <div className="d-flex justify-content-end mt-5">
                                        <button type="submit" className="c-stepper__button">Siguiente</button>
                                    </div>
                                </Form> 
                            )}
                        </Formik>
                    </div>
                    <div ref={tabPaneTwo} className="tab-pane fade" id="profile-pane">
                        <Formik
                            initialValues={stepTwoFormInitialValues}
                            validationSchema={stepTwoFormValidationSchema}
                            onSubmit={async (values, actions) => {
                                const data = await values;
                                console.log('StepOneformData: ', stepOneFormData);
                                console.log('Last Step Data: ', data);
                                createClaim({ variables: { 
                                    firstName: stepOneFormData.firstName,
                                    lastName: stepOneFormData.lastName,
                                    email: stepOneFormData.email,
                                    phone: stepOneFormData.phone,
                                    message: data.message,
                                }});                               
                                backToStepOne();
                                clearRestOfForms();
                                actions.resetForm(stepTwoFormInitialValues);
                            }}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <div className="c-form-group c-form-group--dark">
                                        <label className="c-form-group__label">Mensaje</label>
                                        <p className="u-size-small-2 mb-0">Describe detalladamente la situación que desea reportar.</p>
                                        <Field name="message" as="textarea" className="c-form-group__textarea border-0"cols="30" rows="5" placeholder="Escribir mensaje… "/>
                                        {errors.message && touched.message && <div>{errors.message}</div>}
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center mt-5 u-gap-1">
                                        <button 
                                            onClick={backToStepOne} 
                                            className="c-stepper__button"
                                        >
                                            Atras
                                        </button>
                                        <button 
                                            type="submit" 
                                            className="c-stepper__button active" 
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalSuccess"
                                        >
                                            Enviar
                                        </button>
                                    </div>
                                </Form> 
                            )}
                        </Formik>    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClaimForm