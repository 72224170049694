import React from 'react';
import Hero from './hero';
import SectionInvestment from './section-investment';
import SectionFunding from './section-funding';
import SectionBanner from './section-banner';
import SectionGoals from './section-goals';
import SectionNews from './section-news';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ScrollTopButton from '../../components/scroll-top-button';
import ModalWelcome from '../../components/modal-welcome';
import ModalContact from '../../components/modal-contact';
import ModalContactSuccess from '../../components/modal-contact-success';

const HomePage = () => {
  return (
      <>
        <Header/>
        <Hero/>
        <SectionInvestment/>
        <SectionFunding/>
        <SectionBanner/>
        <SectionGoals/>
        <SectionNews/>
        <ModalWelcome/>
        <ModalContact />
        <ModalContactSuccess/>
        <ScrollTopButton/>
        <Footer/>
      </>
  )
}

export default HomePage;