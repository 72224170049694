import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Header from '../../components/header';
import Footer from '../../components/footer';
import Card from '../../components/card';
import { ScrollToTop } from '../../lib/scroll';
import { useQuery, gql } from '@apollo/client';

const NEWS = gql`
  query GetArticles($page: Int!, $pageSize: Int!, $search: String) {
    articles(pagination: { page: $page, pageSize: $pageSize}, sort: "publishedAt:desc", filters: {title: {containsi: $search}}) {
      data {
        id
        attributes {
          title
          slug
          body
          date
          thumbnail {
              data {
                  id
                  attributes {
                      url
                  }
              }
          }
          createdAt
          updatedAt
          publishedAt
        }
      },
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
`

const PAGE_SIZE = 15;

const NewsPage = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const { loading, error, data, fetchMore } = useQuery(NEWS, {
    variables: { page: page, pageSize: PAGE_SIZE, search: search }
  });
  
  if(error){
    console.log(error)
  }

  const handleSearch = (event) => {
    if(event.key === 'Enter'){
      setSearch(event.target.value);
      fetchMore({
        variables: { search: search }
      });
      event.target.value = "";
      console.log('handleSearch', search);
    }
  }

  const resetNews = () => {
    setSearch("");
    fetchMore({
      variables: { search: "" }
    });
  }

  const handlePagination = (event) => {
    event.preventDefault();
    setSearch("");
    switch (event.target.id) {
      case 'pagination-prev':
        paginatePrev();
        break;
      case 'pagination-next':
        paginateNext();
        break;
      default:
        const pageItemId = parseInt(event.target.id.substring(event.target.id.length - 1));
        paginateTo(pageItemId);
    }
    fetchMore({
      variables: { page: page }
    });
    ScrollToTop();
  }

  const paginatePrev = () => {
    const prev = page - 1;
    if(prev >= 1){
      setPage(prev);
    };
  }

  const paginateNext = () => {
    const next = page + 1;
    if(next <= data?.articles?.meta?.pagination?.pageCount) {
      setPage(next);
    }
  }

  const paginateTo = (toPage) => {
    if((toPage >= 1 || toPage <= data?.articles?.meta?.pagination?.pageCount) && toPage !== page){
      setPage(toPage);
      // console.log('paginateTo pageCount:', data?.articles?.meta?.pageCount)
      // console.log('paginateTo toPage:', toPage)
    } 
  }
 
  return (
      <>
        <Header background={"c-header--white"}/>
        <div className="l-news">
          <div className="l-news__header">
              <h3 className="l-news__title"> 
                <Link to="/"><span className="icon-arrow-left"></span></Link> 
                Noticias
              </h3>
              <p className="l-news__subtitle">Artículos, Últimas novedades y Participaciones</p>
          </div>
          <div className="align-items-center d-flex justify-content-between mb-2 flex-wrap">
            <p>Ordenado por la más reciente</p>
            <div className="c-form-search">
              <input type="text" className="c-form-search__input" placeholder="Búsqueda por nombre" onKeyUp={handleSearch} />
              <div className="c-form-search__icon">
                <span className="icon-search"></span>
              </div>
            </div>
          </div>
          {loading && (
            <p>Cargando Noticias...</p>
          )}
          {search !== "" && (
            <div >
              <p style={{textAlign: 'center', marginBottom: '10px'}}> 
                Volver a reiniciar las noticias&nbsp;
                <b style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={resetNews}>
                  click aquí
                </b>
              </p>
            </div>
          )}
          
          {data?.articles?.data?.length === 0 && (
            <p style={{textAlign: 'center'}}>
              No se ha encontrado ninguna Noticia con <b>"{search}"</b>
            </p>
          )}

          {data?.articles?.data?.length > 0 && (
            <>
              <div className="l-news__grid">
                {data?.articles?.data?.map(article => (
                  <Card 
                      key={article.id}
                      id={article.id}
                      title={article.attributes?.title}
                      body={article.attributes?.body}
                      date={article.attributes?.date}
                      publishedAt={article.attributes?.publishedAt}
                      thumbnail={article.attributes?.thumbnail?.data?.attributes?.url}
                  />
                ))}
              </div>
              {data?.articles?.meta && (
                <div className="d-flex justify-content-center mt-5 pt-5">
                  <nav aria-label="Page navigation example">
                      <ul className="pagination c-form-pagination">
                          <li className="page-item" onClick={handlePagination}>
                            <button id="pagination-prev" className="page-link">Anterior</button>
                          </li>
                          {[...Array(data?.articles?.meta?.pagination?.pageCount).keys()].map(pageItem => 
                            <li key={`page-item-${pageItem + 1}`} className={pageItem + 1 === page ? "page-item active" : "page-item"} onClick={handlePagination}>
                              <button id={`pagination-${pageItem + 1}`} className="page-link">
                                {pageItem + 1}
                              </button>
                            </li>
                          )}
                          <li className="page-item" onClick={handlePagination}>
                            <button id="pagination-next" className="page-link">Próxima</button>
                          </li>
                      </ul>
                  </nav>
                </div>
              )}
            </>
          )}
        </div>
        <Footer/>
      </>
  )
}

export default NewsPage