const getStrapiUrl = (path = "") => {
    return `${
        process.env.REACT_APP_API_URL || "http://localhost:1337"
    }${path}`;
}

const handleStrapiUrl = (url) => {
    let buildUrl = "";
    
    if(url){
        buildUrl = url.startsWith("/") ? getStrapiUrl(url) : url;
    }
    
    return buildUrl;
}

export default handleStrapiUrl;