import React from 'react';
import handleStrapiUrl from '../../../lib/handle-strapi-url';
import { Link } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';

const FUNDING = gql`
  query GetFunding {
    funding {
      data {
        id
        attributes {
          Links {
            id
            title
            icon {
              data {
                id
                attributes {
                  url
                  alternativeText
                  caption
                  updatedAt
                  createdAt
                }
              }
            }
            fund {
              data {
                id
                attributes{
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`

const SectionFunding = () => {
  const { loading, error, data } = useQuery(FUNDING);
    
  if(error){
    console.log('SectionFunding: ', error);
  }

  if(loading){
    return <p>Cargando SectionFunding...</p>
  }

  return (
    <section className="section-funding">
      <h3 className="section-funding__title">Nuestros fondos</h3>
      <div className="section-funding__content">
        {data?.funding?.data.attributes.Links.map(link => {
          if(link.fund.data == null) return '';
          return(
            <Link to={`fund/${link.fund.data.id}`} className="c-card-funding">
            <img 
              className="c-card-funding__icon"
              src={handleStrapiUrl(link.icon.data.attributes.url)} 
              alt="" 
            />
            <p className="c-card-funding__text">
              {link.fund?.data?.attributes?.title}
            </p>
          </Link>
          )
        })}
      </div>
    </section>
  )
}

export default SectionFunding