import React from 'react';
import FaqItem from '../faq-item';
import { useQuery, gql } from '@apollo/client';

const FAQS = gql`
    query GetFaqs {
        frequentAsked{
            data {
                id
                attributes {
                    Faq {
                        id
                        title
                        Questions {
                            id
                            title
                            body
                        }
                    }
                    createdAt
                    updatedAt
                }
            }
        }
  }
`

const AccordionItem = () => {
    const { loading, error, data } = useQuery(FAQS);

    if(error){
        console.log('Accordion: ', error);
    };

    if(loading){
        return <p>Cargando Accordion Item...</p>
    }

    if(!loading){
        console.log('Accordion: ', data);
    }

    return (
        <>
            {data?.frequentAsked?.data?.attributes?.Faq?.map(faq => (
                <div key={`accordion-item${faq.id}`} className="accordion-item">
                    <h2 
                        className="accordion-header" 
                        id={`accordion-item-heading-${faq.id}`}
                    >
                        <button 
                            className="accordion-button" 
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target={`#collapse-accordion-item-${faq.id}`}
                            aria-expanded="true" 
                            aria-controls={`collapse-accordion-item-${faq.id}`}
                        >
                            <span>{faq.title}</span>
                        </button>
                    </h2>
                    <div 
                        id={`collapse-accordion-item-${faq.id}`}
                        className="accordion-collapse collapse" 
                        aria-labelledby={`accordion-item-heading-${faq.id}`} 
                        data-bs-parent="#faqAccordion">
                        <div className="accordion-body">
                            <div className="col-lg-7">
                                {faq.Questions.map(question => (
                                    <FaqItem 
                                        key={`#faqItem${question.id}`}
                                        id={question.id}
                                        title={question.title}
                                        body={question.body}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default AccordionItem