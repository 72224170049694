import React from 'react'
import Header from '../../components/header';
import Footer from '../../components/footer';
import SliderCenter from '../../components/slider-center';

const Memories = () => {
  return (
    <>
        <Header background={"c-header--white"}/>
        <div className="l-memories">
            <div className="l-memories__header">
                <div className="l-memories__title">Memorias Anuales</div>
                <div className="l-memories__subtitle">
                    Presiona sobre cada uno para poder descargar los documentos disponible
                </div>
            </div>
            <div className="l-memories__body">
                <SliderCenter className="c-slider-doc"/>
            </div>
        </div>
        <Footer/>
    </>
  )
}

export default Memories