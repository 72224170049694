import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import TimeLine from '../../components/timeline';
import Team from '../../components/team';
import ScrollTopButton from '../../components/scroll-top-button';
import Hero from './hero';
import OurPillars from './our-pillars';
// import InvestmentMember from './investment-member';

const About = () => {
  return (
   <>
    <Header />
    <Hero/>
    <OurPillars/>
    <TimeLine/>
    <Team/>
    {/* <InvestmentMember/> */}
    <ScrollTopButton/>
    <Footer />
   </>
  )
}

export default About   