import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import icon_logo_svg from '../../assets/images/icon-logo.svg';
import check_svg from '../../assets/images/check.svg';

const InvestmentFund = () => {
  return (
    <>
        <Header background={"c-header--white"}/>
        <main className="l-faq">
            <div className="l-faq__header">
                <div className="container">
                    <div className="col-lg-12">
                        <h2 className="l-faq__title">Fondos de inversión</h2>
                    </div>
                </div>
            </div>
            <div className="l-faq__body">
                <div className="container">
                    <div className="col-lg-7">
                        <div className="c-faq-item">
                            <div className="c-faq-item__header">
                                <img className="c-faq-item__icon" src={icon_logo_svg} alt=""/>
                                <h4 className="c-faq-item__title">¿Qué es un Fondo de inversión? </h4>
                            </div>
                            <div className="c-faq-item__body">
                                <p>
                                    Un fondo de inversión es un patrimonio separado que se forma a través de los aportes de inversionistas
                                    con el fin de diversificar su portafolio y aumentar su rentabilidad.
                                </p>
                            </div>
                        </div>
                        <hr/>
                        <div className="c-faq-item mt-4">
                            <div className="c-faq-item__header">
                                <img className="c-faq-item__icon" src={icon_logo_svg} alt=""/>
                                <h4 className="c-faq-item__title">¿Qué son los Fondos Abiertos o Mutuos?</h4>
                            </div>
                            <div className="c-faq-item__body">
                                <p>
                                    Son aquellos que admiten la incorporación y el retiro de aportantes conforme las reglas establecidas en el
                                    reglamento interno, por lo que el monto del patrimonio y el número de las cuotas emitidas es variable, continua
                                    e ilimitada. Su plazo de duración es indefinido y las cuotas de participación colocadas entre el público no son
                                    negociables ni transables, ya que son redimibles directamente por el mismo fondo que los emite, a través de la sociedad
                                    administradora que los gestiona, excepto en los casos que se establezcan mediante reglamento. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" style={{background: "#4a4a4a12", padding: "4rem"}}>
                    <div className="container">
                        <div className="col-lg-5">
                            <h4 className="c-faq-item__title mb-5">Características</h4>
                            <div className="align-items-center d-flex u-gap-1 mb-1">
                                <img src={check_svg} alt=""/>
                                <p>Tienen duración indefinida, es decir, no tienen fecha de vencimiento. </p>
                            </div>
                            <div className="align-items-center d-flex u-gap-1 mb-1">
                                <img src={check_svg} alt=""/>
                                <p>Patrimonio y número de cuotas de participación son variables. </p>
                            </div>
                            <div className="align-items-center d-flex u-gap-1 mb-1">
                                <img src={check_svg} alt=""/>
                                <p>El inversionista o aportante puede suscribir (comprar) o redimir (vender) directamente con el fondo las cuotas adquiridas.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-5 pb-1">
                    <div className="col-lg-7">
                        <div className="c-faq-item">
                            <div className="c-faq-item__header">
                                <img className="c-faq-item__icon" src={{icon_logo_svg}} alt=""/>
                                <h4 className="c-faq-item__title">¿Qué son los Fondos Cerrados?</h4>
                            </div>
                            <div className="c-faq-item__body">
                                <p>
                                    Son aquellos cuyo número de cuotas de participación colocadas entre el público es fijo y no son redimibles a solicitud
                                    de los aportantes directamente por el mismo fondo que los emite, excepto en los casos establecidos en la Ley No. 249-17,
                                    del Mercado de Valores, por lo que son negociables a través de las bolsas de valores. Estos fondos pueden tener un plazo de duración
                                    determinado o indeterminado, según el tipo de fondo de que se trate, conforme a lo dispuesto reglamentariamente. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" style={{background: "#4a4a4a12", padding: "4rem"}}>
                    <div className="container">
                        <div className="col-lg-6">
                            <h4 className="c-faq-item__title mb-5">Características</h4>
                            <div className="align-items-center d-flex u-gap-1 mb-1">
                                <img src={check_svg} alt=""/>
                                <p>Tienen un plazo definido. </p>
                            </div>
                            <div className="align-items-center d-flex u-gap-1 mb-1">
                                <img src={check_svg} alt=""/>
                                <p>La cantidad de cuotas de participación colocadas entre el público no varía. </p>
                            </div>
                            <div className="align-items-center d-flex u-gap-1 mb-1">
                                <img src={check_svg} alt=""/>
                                <p>
                                    Las cuotas de estos fondos no son redimibles directamente por el fondo cerrado, las cuotas de este tipo de fondos son
                                    negociables en el mercado secundario de valores a través de los puestos de bolsa inscritos en la Bolsa de Valores.
                                </p>
                            </div>
                            <div className="align-items-center d-flex u-gap-1 mb-1">
                                <img src={check_svg} alt=""/>
                                <p>
                                    Los fondos cerrados pueden ser de tipo: Inmobiliario, Desarrollo Inmobiliario, Desarrollo de Sociedades, Renta Fija.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" style={{background: "#24515e1f", padding: "7rem 0"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-5">
                                <h4 className="c-faq-item__title mb-5 text-center">Ventajas de Invertir en Fondos de Inversión </h4>
                            </div>
                            <div className="col-lg-4 offset-lg-2 col-md-6">
                                <div className="align-items-center d-flex u-gap-1 mb-1">
                                    <img src={check_svg} alt=""/>
                                    <h4 className="c-faq-item__title">Diversificación </h4>
                                </div>
                                <div className="align-items-center d-flex u-gap-1 mb-1">
                                    <img src={check_svg} alt=""/>
                                    <h4 className="c-faq-item__title">Manejo profesional</h4>
                                </div>
                                <div className="align-items-center d-flex u-gap-1 mb-1">
                                    <img src={check_svg} alt=""/>
                                    <h4 className="c-faq-item__title">Transparencia</h4>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="align-items-center d-flex u-gap-1 mb-1">
                                    <img src={check_svg} alt=""/>
                                    <h4 className="c-faq-item__title">Maximiza rendimientos:</h4>
                                </div>
                                <div className="align-items-center d-flex u-gap-1 mb-1">
                                    <img src={check_svg} alt=""/>
                                    <h4 className="c-faq-item__title">Fácil acceso</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer/>
    </>
  )
}

export default InvestmentFund