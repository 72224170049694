import React from 'react';
import handleStrapiUrl from '../../../lib/handle-strapi-url';
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useQuery, gql } from '@apollo/client';

const SLIDESHOW = gql`
  query GetSlideshow {
    slideshow {
      data {
        id
        attributes {
          title
          Slider {
            data {
              id
              attributes {
                url
                alternativeText
                caption
                size
                updatedAt
                createdAt
              }
            }
          }
        }
      }
    }
  }
`

const Hero = () => {
  const { loading, error, data } = useQuery(SLIDESHOW);
    
  if(error){
    console.log('SLIDESHOW: ', error);
  }

  if(loading){
    return <p>Cargando SLIDESHOW...</p>
  }

  return (
    <div className="l-hero">
      <div id="carouselExampleCaptions" className="carousel slide c-carousel-hero" data-bs-ride="carousel">
          <div className="carousel-indicators">
            {data?.slideshow?.data?.attributes?.Slider.data.map((slide, index) => (
              <button
                key={`heroSlideBtn${slide.id}`} 
                type="button" 
                data-bs-target="#carouselExampleCaptions" 
                data-bs-slide-to={`${index}`} 
                className={index === 0 ? "active" : ""} 
                aria-current={index === 0 ? "true" : "false"} 
                aria-label={`slide ${index + 1}`}>
              </button>
            ))}
          </div>
          <div className="carousel-inner">
            {data?.slideshow?.data?.attributes?.Slider.data.map((slide, index) => (
              <div className={index === 0 ? "carousel-item active" : "carousel-item"}>
                <img
                  key={`carouselItem${slide.id}`} 
                  src={handleStrapiUrl(slide.attributes.url)} 
                  className="d-block w-100" 
                  alt="..."
                />
              </div>
            ))}
            <div className="l-hero__content">
              <h2 className="l-hero__title" >
                <ReactMarkdown
                    children={data?.slideshow?.data?.attributes?.title}  
                    remarkPlugins={[remarkGfm]}
                />
              </h2>
              <div className="d-flex u-gap-1">
                  <Link to="/about" className="c-btn c-btn--outline">Conocer más</Link>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#modalContact" className="c-btn c-btn--primary">
                    Contáctanos
                  </a>
              </div>
            </div>
          </div>
      </div>
      <a href="#investment" className="c-btn-scrollDown"> 
        <span className="icon-arrow-down"></span>
      </a>
    </div>
  )
}

export default Hero