import React from 'react';
import { Link } from "react-router-dom";
import Header from '../../components/header';
import Footer from '../../components/footer';
import ModalComplaintSuccess from '../../components/modal-complaint-success';
import ComplaintForm from '../../components/complaint-form';
import ClaimForm from '../../components/claim-form';

const Complaint = () => {
  return (
   <>
    <Header background={"c-header--white"}/>
    <div className="l-complaint">
        <div className="l-complaint__header">
            <Link to="/" className="l-news-detail__button">
                <span className="icon-arrow-left"></span>Volver atrás
              </Link>
        </div>
        <div className="l-complaint__body">
            <p className="u-font-bold u-size-small">Seleccionar tipo</p>
            <div className="c-tabs-faq">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button 
                            className="nav-link active" 
                            id="nav-home-tab" 
                            data-bs-toggle="tab" 
                            data-bs-target="#nav-home" 
                            type="button" 
                            role="tab" 
                            aria-controls="nav-home" 
                            aria-selected="true"
                        >
                            Canal de denuncias
                        </button>
                        <button 
                            className="nav-link" 
                            id="nav-profile-tab" 
                            data-bs-toggle="tab" 
                            data-bs-target="#nav-profile" 
                            type="button" 
                            role="tab" 
                            aria-controls="nav-profile" 
                            aria-selected="false"
                        >
                            Canal de reclamaciones
                        </button>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex="0">
                   <div className="row">
                    <div className="col-lg-6">
                        <p className="mb-4 mt-2">
                            En virtud del Reglamento que regula la Prevención del Lavado de Activos, 
                            Financiamiento del Terrorismo y de la Proliferación de Armas de Destrucción 
                            Masiva en el Mercado de Valores, se establece el presente canal de denuncias 
                            relativas a posibles tipologías o conductas tipificadas relacionadas al lavado 
                            de activos, financiamiento del terrorismo o de la proliferación de armas de 
                            destrucción masiva (LAFT & PADM), las cuales podrán ser realizadas de forma 
                            anónima e involucrar a algún colaborador o cliente de ALTIO. 
                            Las mismas serán presentadas al Comité de Cumplimiento de Prevención de 
                            Lavado de Activos, Financiamiento del Terrorismo y de 
                            la Proliferación de Armas de Destrucción Masiva.
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <ComplaintForm/>
                    </div>
                   </div>
                  </div>
                  <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex="0">
                    <div className="row">
                        <div className="col-lg-6">
                            <p className="mb-4 mt-2">
                                En virtud del Reglamento que regula las Sociedades Administradoras 
                                y los Fondos de Inversión, se establece un canal de denuncias, 
                                quejas y reclamaciones de los colaboradores y clientes de ALTIO, 
                                los aportantes de los fondos de inversión gestionados, los terceros 
                                y el público en general, a fin de que puedan reportar situaciones 
                                de conductas irregulares o delitos, distintos a los relacionados 
                                a LAFT & PADM. La mismas podrán ser realizadas de forma anónima.
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <ClaimForm/>
                        </div>
                       </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <ModalComplaintSuccess/>
    <Footer/>
   </>
  )
}

export default Complaint