import React from 'react';
import form_svg from '../../assets/images/form.svg';
import ModalContactForm from '../modal-contact-form';

const ModalContact = () => {
    return (
        <div
            className="modal fade c-modal-info"
            id="modalContact"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="align-items-center c-modal-info__header d-flex p-5 u-gap-1">
                        <img src={form_svg} alt="" className="mr-2"/>
                        <h5 className="c-modal-info__title">Formulario <span className="u-font-normal">de contacto</span></h5>
                    </div>
                    <ModalContactForm/>
                </div>
            </div>
        </div>
    )
}

export default ModalContact;
