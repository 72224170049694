import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Modal = ({
    id,
    fullname,
    body,
}) => {
  return (
    <div 
        className="modal fade c-modal-info" 
        id={`modal${id}`} 
        aria-labelledby={`modal${id}`} 
        aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="c-modal-info__header">
            <h5 className="c-modal-info__title">{fullname}</h5>
          </div>
          <div className="modal-body">
            <ReactMarkdown 
              children={body}  
              remarkPlugins={[remarkGfm]}
            />
          </div>
          <div className="c-modal-info__footer">
            <button
              type="button"
              className="c-btn c-btn--outline-dark"
              data-bs-dismiss="modal"
            >
              Cerrar ventana
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal