import React from 'react';
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useQuery, gql } from '@apollo/client';

const FUNDLINKS = gql`
  query GetFunding {
    funding {
      data {
        id
        attributes {
          Links {
            id
            title
            icon {
              data {
                id
                attributes {
                  url
                  alternativeText
                  caption
                  updatedAt
                  createdAt
                }
              }
            }
            fund {
              data {
                id
              }
            }
          }
        }
      }
    }
  } 
`

const FundLinks = () => {
  const { loading, error, data } = useQuery(FUNDLINKS);

  if(error){
    console.log('FundLinks: ', error);
  };

  if(loading){
      return <p>Cargando FundLinks...</p>
  }

  return (
    <>
      {data?.funding?.data?.attributes?.Links.map(link => {
        if(link.fund.data == null) return '';
        return (
          <li key={link.id}>
            <Link to={`/fund/${link.fund.data.id}`} className="dropdown-item">
              <ReactMarkdown
                  children={link.title}  
                  remarkPlugins={[remarkGfm]}
              />
            </Link>
          </li>
        )
      })}
    </>
  )
}

export default FundLinks