import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';

const POST_CONTACT = gql`
    mutation createContact(
        $firstName: String!
        $lastName: String!
        $email: String!
        $message: String!
    ){
        createContact(
            data: {
                firstname: $firstName
                lastname: $lastName
                email: $email
                message: $message
            }
        ){
            data {
                id
                attributes {
                    firstname
                    lastname
                    email
                    message
                }
            }
        }
    }  
`;

const contactFormValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Nombre muy corto!')
        .max(50, 'Nombre muy largo!')
        .required('El campo es requerido.'),
    lastName: Yup.string()
        .min(2, 'Apellido muy corto!')
        .max(50, 'Apellido muy largo!')
        .required('El campo es requerido.'),
    email: Yup.string()
        .email('Correo electrónico inválido.')
        .required('El campo es requerido.'),
    message: Yup.string()
        .min(2, 'Mensaje muy corto!')
        .required('El campo es requerido.'),
});

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
}

const ModalContactForm = () => {
    const [createContact, { loading, error }] = useMutation(POST_CONTACT);

    if (loading) return <p>Enviando información del formulario</p>

    if (error) console.log('ModalContactForm : error', error.message)

    const toogleModals = (form) => {
        const getModalSuccessTitle = document.getElementsByClassName('c-modal-success__title');
        getModalSuccessTitle[0].children[0].innerHTML = `${form?.firstName} ${form?.lastName}`;
        const getModalContact = document.getElementById("modalContact");
        getModalContact.classList.remove('show')
        getModalContact.style = "display: none";
        const getModalSuccess = document.getElementById("modalSuccess");
        getModalSuccess.classList.add('show');
        getModalSuccess.style = "display: block";
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={contactFormValidationSchema}
            onSubmit={async (values) => {
                const formData = await values;
                createContact({ variables: { 
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    message: formData.message,
                }})
                toogleModals(formData);
            }}
        > 
        {({ errors, touched }) => (
            <Form>
                <div className="modal-body">
                    <div className="c-form-group c-form-group--dark">
                        <label className="c-form-group__label">¿Cuál es tu nombre?</label>
                        <Field name="firstName" className="c-form-group__input" type="text" placeholder="Ej: Carlos Mota "/>
                        {errors.firstName && touched.firstName && <div>{errors.firstName}</div>}
                    </div>
                    <div className="c-form-group c-form-group--dark">
                        <label className="c-form-group__label">Apellidos</label>
                        <Field name="lastName" className="c-form-group__input" type="text" placeholder="Ingresa tu Apellidos aquí"/>
                        {errors.lastName && touched.lastName && <div>{errors.lastName}</div>}
                    </div>
                    <div className="c-form-group c-form-group--dark">
                        <label className="c-form-group__label">Correo electrónico</label>
                        <Field name="email" className="c-form-group__input" type="email" placeholder="Ingresa tu email aquí"/>
                        {errors.email && touched.email && <div>{errors.email}</div>}
                    </div>
                    <div className="c-form-group c-form-group--dark">
                        <label className="c-form-group__label">Cuerpo de Mensaje</label>
                        <Field name="message" as="textarea" cols="30" rows="4" className="c-form-group__textarea" placeholder="Mensaje"></Field>
                        {errors.message && touched.message && <div>{errors.message}</div>}
                    </div>
                </div>
                <div className="c-modal-info__footer u-gap-1 flex-wrap">
                    <button 
                        className="c-btn c-btn--outline-dark flex-grow-1"
                        data-bs-dismiss="modal">
                            Mas tarde
                    </button>
                    <button
                        type="submit"
                        className="c-btn c-btn--secondary flex-grow-1"
                        data-bs-dismiss="modal"
                    >
                        Enviar
                    </button>
                </div>
            </Form>
        )}
        </Formik>
    )
}

export default ModalContactForm