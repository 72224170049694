import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

/** TEMPLATE */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "slick-carousel/slick/slick.css"; 
import './assets/icons/style.css'; 
import './assets/sass/styles.min.css'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
