import React from 'react';
import handleStrapiUrl from '../../lib/handle-strapi-url';
import { Link } from "react-router-dom";
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { timeFromNow } from '../../lib/times-formats';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const NEWS_BY_ID = gql`
    query GetArticle($id: ID!) {
        article(id: $id) {
            data {
                id
                attributes {
                    title
                    body
                    thumbnail {
                        data {
                            id
                            attributes {
                                url
                            }
                        }
                    }
                    createdAt
                    updatedAt
                    publishedAt
                }
            }
        }
    }
`

const NewDetails = () => {
    const { id } = useParams();

    const { loading, error, data } = useQuery(NEWS_BY_ID, {
        variables: { id: id }
    })

    if (error) console.log('NewDetails, Error:', error)

    if (loading) return <p>Loading...</p>

    return (
        <>
            <Header background={"c-header--white"}/>
            <div className="l-news-detail">
                <div className="l-news-detail__content">
                    <div className="l-news-detail__header">
                        <Link to="/" className="l-news-detail__button">
                            <span className="icon-arrow-left"></span>Volver atrás
                        </Link>
                        <h2 className="l-news-detail__title">
                            {data?.article?.data?.attributes?.title}
                        </h2>
                        <div className="l-news-detail__image">
                            <img src={handleStrapiUrl(data?.article?.data?.attributes?.thumbnail?.data?.attributes?.url)} alt="" />
                            <div className="l-news-detail__date">
                                <p>{timeFromNow(data?.article?.data?.attributes?.publishedAt)}</p><span className="icon-calendar ml-1"></span>
                            </div>
                        </div>
                    </div>
                    <div className="l-news-detail__body">
                        <ReactMarkdown 
                            children={data?.article?.data?.attributes?.body}  
                            remarkPlugins={[remarkGfm]}
                        />
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default NewDetails