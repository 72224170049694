import React from 'react';
import handleStrapiUrl from '../../../lib/handle-strapi-url';
import info_icon_build_svg from '../../../assets/images/info-icon-build.svg';
import AccordionDocumentItem from '../../../components/accordion-document-item';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import TeamItem from '../../../components/team-item';
import Modal from '../../../components/modal';

const SectionTab = ({props}) => {
    console.log('SectionTab', props);
    const listOfComponents = [
        "ComponentPowerbiPowerbi",
        "ComponentInfoInfo",
        "ComponentCategoryCategory",
        "ComponentActivesActives",
        "ComponentTeamTeam"
    ];

    return (
        <section className="section-tabs">
            <div className="c-tabs-funds">
                <h4 className="c-tabs-funds__title">Seleccionar una opción para ver información</h4>
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    {props?.fund?.data?.attributes?.Tabs?.map(tab => {
                        if(listOfComponents.includes(tab.__typename)){
                            if(tab.__typename === "ComponentPowerbiPowerbi"){
                                return(
                                    <li key="nav-item-value" className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-value-tab" data-bs-toggle="pill" data-bs-target="#pills-value" type="button" role="tab" aria-controls="pills-value" aria-selected="true">Valor cuota</button>
                                    </li>
                                )
                            }
                            if(tab.__typename === "ComponentInfoInfo"){
                                return (
                                    <li key="nav-item-data" className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-data-tab" data-bs-toggle="pill" data-bs-target="#pills-data" type="button" role="tab" aria-controls="pills-data" aria-selected="false">Datos generales</button>
                                    </li>
                                )
                            }
                            if(tab.__typename === "ComponentCategoryCategory"){
                                return (
                                    <li key="nav-item-document" className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-document-tab" data-bs-toggle="pill" data-bs-target="#pills-document" type="button" role="tab" aria-controls="pills-document" aria-selected="false">Documentos</button>
                                    </li>
                                )
                            }
                            if(tab.__typename === "ComponentActivesActives"){
                                return(
                                    <li key="nav-item-asset" className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-asset-tab" data-bs-toggle="pill" data-bs-target="#pills-asset" type="button" role="tab" aria-controls="pills-asset" aria-selected="false">Activos</button>
                                    </li>
                                )
                            }
                            if(tab.__typename === "ComponentTeamTeam"){
                                return(
                                    <li key="nav-item-asset" className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-team-tab" data-bs-toggle="pill" data-bs-target="#pills-team" type="button" role="tab" aria-controls="pills-team" aria-selected="false">Comité</button>
                                    </li>
                                )
                            }
                        }
                    })}
                    
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    {props?.fund?.data?.attributes?.Tabs?.map((tab, index) => {
                        if(listOfComponents.includes(tab.__typename)){
                            if(tab.__typename === "ComponentPowerbiPowerbi"){
                                return(
                                    <div key="PowerbiTab" className={index === 0 ? "tab-pane fade active show" : "tab-pane fade"} id="pills-value" role="tabpanel" aria-labelledby="pills-value-tab">
                                        <div className="align-items-center d-flex flex-column mt-4">
                                            <div className="c-tabs-funds__iframe">
                                                <iframe title={tab.title} src={tab.source} frameBorder="0" allowFullScreen={true}></iframe>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            if(tab.__typename === "ComponentInfoInfo"){
                                return (
                                    <div key="InfoTab" className={index === 0 ? "tab-pane fade show" : "tab-pane fade"} id="pills-data" role="tabpanel" aria-labelledby="pills-data-tab">
                                        <div className="d-flex align-items-center d-flex flex-column">
                                            <div className="c-card-data">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        {tab.LeftSide.map((card, index) => (
                                                            <div key={`ls`+index} className="c-card-data__item">
                                                                <div className="c-card-data__name">{card.title} </div>
                                                                <div className="c-card-data__data">{card.data}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        {tab.RightSide.map((card, index) => (
                                                            <div key={`rs`+index} className="c-card-data__item">
                                                                <div className="c-card-data__name">{card.title} </div>
                                                                <div className="c-card-data__data">{card.subtitle}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            if(tab.__typename === "ComponentCategoryCategory"){
                                return (
                                    <div key="CategoryTab" className={index === 0 ? "tab-pane fade show" : "tab-pane fade"} id="pills-document" role="tabpanel" aria-labelledby="pills-document-tab">
                                        <div className="d-flex align-items-center d-flex flex-column">
                                            <div className="accordion c-accordion-document" id="accordionExample">
                                                {tab.archive?.data?.attributes.Documents.map(document => (
                                                    <AccordionDocumentItem 
                                                        key={`AccordionDocumentItem-${document.id}`}
                                                        id={document.id}
                                                        title={document.title}
                                                        quantity={document.Files.length} 
                                                        files={document.Files}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            if(tab.__typename === "ComponentActivesActives"){
                                return(
                                    <div key="ActivesTab" className={index === 0 ? "tab-pane fade show" : "tab-pane fade"} id="pills-asset" role="tabpanel" aria-labelledby="pills-asset-tab">
                                        <div className="section-tabs__content">
                                            {tab.properties?.map(property => (
                                                <>
                                                    <div className="c-card-info">
                                                        <div className="c-card-info__image">
                                                            <img src={handleStrapiUrl(property.thumbnail?.data?.attributes?.url)} alt=""/>
                                                            <div className="c-card-info__icon">
                                                                <img src={info_icon_build_svg} alt=""/>
                                                            </div>
                                                        </div>
                                                        <h3 className="c-card-info__title">{property.title}</h3>
                                                        <p className="c-card-info__text">
                                                            {property.subtitle}
                                                        </p>
                                                        <a href="#" className="c-card-info__link" data-bs-toggle="modal" data-bs-target={`#modal${property.id}`}>Ver más</a>
                                                    </div>

                                                    <div className="modal fade c-modal-info" id={`modal${property.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="c-modal-info__header">
                                                                    <h5 className="c-modal-info__title">{property.title}</h5>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <ReactMarkdown
                                                                        children={property.body} 
                                                                        remarkPlugins={[remarkGfm]}
                                                                    />
                                                                </div>
                                                                <div className="c-modal-info__footer">
                                                                <button type="button" className="c-btn c-btn--outline-dark" data-bs-dismiss="modal">
                                                                    Cerrar ventana
                                                                </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                )
                            }
                            if(tab.__typename === "ComponentTeamTeam"){
                                const team = {title:tab.title, members:[...tab.Members]};
                                return(
                                    <div key="TeamTab" className={index === 0 ? "tab-pane fade show" : "tab-pane fade"} id="pills-team" role="tabpanel" aria-labelledby="pills-team-tab">
                                        <div className="l-about" id="ourTeam">
                                            {/* {team.title && (
                                                <div className="l-about__header justify-content-center">
                                                    <h3 className="l-about__title">
                                                        {team.title}
                                                    </h3>
                                                </div>
                                            )} */}
                                            <div className="l-about__body">
                                                <div className="c-profile">
                                                    {team.members?.map(member => (
                                                        <TeamItem
                                                        id={member.id}
                                                        key={`member${member.id}`} 
                                                        fullname={member.fullname} 
                                                        role={member.role}
                                                        linkedin={member.linkedin}
                                                        picture={member.picture} 
                                                        />
                                                    ))}
                                                </div>
                                                {team.members?.map(member => {
                                                    if(member.body == null) return '';
                                                        return(
                                                            <Modal
                                                                id={member.id}
                                                                key={`modal${member.id}`} 
                                                                fullname={member.fullname} 
                                                                body={member.body} 
                                                            />
                                                        )
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    
                                )
                            }
                        }
                    })}
                </div>
            </div>
        </section>
    )
}

export default SectionTab