import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import WorkWithUsForm from '../../components/work-with-us-form';
import ModalContactSuccess from '../../components/modal-contact-success';

const WorkWithUs = () => {
  return (
    <>
      <Header/>
      <WorkWithUsForm/>
      <ModalContactSuccess/>
      <Footer/>
    </>
  )
}

export default WorkWithUs