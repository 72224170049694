import React from 'react';
import guard_svg from '../../../assets/images/guard.svg';
import rendimiento_svg from '../../../assets/images/rendimiento.svg';
import capital_humano_svg from '../../../assets/images/capital-humano.svg';
import gestion_svg from '../../../assets/images/gestion.svg';
import diversificacion_svg from '../../../assets/images/diversificacion.svg';
import impacto_svg from '../../../assets/images/impacto.svg';

const OurPillars = () => {
  return (
    <>
      <div className="l-about__header">
        <img src={guard_svg} alt="" className="l-about__icon" />
        <h3 className="l-about__title">Nuestros pilares</h3>
      </div>
      <div className="l-about__body">
        <div className="c-pillar">
          <div className="c-pillar__item">
            <div
              className="c-pillar__icon"
              data-bs-toggle="modal"
              data-bs-target="#modalRendimiento"
            >
              <img src={rendimiento_svg} alt="" />
            </div>
            <div className="c-pillar__name">Rendimiento</div>
          </div>
          <div className="c-pillar__item">
            <div
              className="c-pillar__icon"
              data-bs-toggle="modal"
              data-bs-target="#modalCapital"
            >
              <img src={capital_humano_svg} alt="" />
            </div>
            <div className="c-pillar__name">Capital Humano</div>
          </div>
          <div className="c-pillar__item">
            <div
              className="c-pillar__icon"
              data-bs-toggle="modal"
              data-bs-target="#modalRiesgo"
            >
              <img src={gestion_svg} alt="" />
            </div>
            <div className="c-pillar__name">Gestión de Riesgo</div>
          </div>
          <div className="c-pillar__item">
            <div
              className="c-pillar__icon"
              data-bs-toggle="modal"
              data-bs-target="#modalDiversificacion"
            >
              <img src={diversificacion_svg} alt="" />
            </div>
            <div className="c-pillar__name">Diversificación</div>
          </div>
          <div className="c-pillar__item">
            <div
              className="c-pillar__icon"
              data-bs-toggle="modal"
              data-bs-target="#modalImpacto"
            >
              <img src={impacto_svg} alt="" />
            </div>
            <div className="c-pillar__name">Impacto Social</div>
          </div>
        </div>
      </div>
      <div
        className="modal fade c-modal-info"
        id="modalRendimiento"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="c-modal-info__header">
              <h5 className="c-modal-info__title">Rendimiento</h5>
            </div>
            <div className="modal-body">
              <p>
                Buscamos generar valor en una escala 360, donde ejecutamos ideas
                de inversión que fomenten el desarrollo de nuestro país, del
                mercado de valores y del patrimonio de nuestros inversionistas, a
                quienes consideramos socios en el proceso de inversión.
              </p>
            </div>
            <div className="c-modal-info__footer">
              <button
                type="button"
                className="c-btn c-btn--outline-dark"
                data-bs-dismiss="modal"
              >
                Cerrar ventana
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade c-modal-info"
        id="modalCapital"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="c-modal-info__header">
              <h5 className="c-modal-info__title">Capital Humano</h5>
            </div>
            <div className="modal-body">
              <p>
                Nuestros equipos buscan activamente la generación de ideas de
                inversión ajustadas al perfil de riesgo-retorno de cada uno de los
                fondos. Con esto buscamos ofrecerle resultados del más alto nivel
                en términos de diversificación a nuestros clientes
              </p>
            </div>
            <div className="c-modal-info__footer">
              <button
                type="button"
                className="c-btn c-btn--outline-dark"
                data-bs-dismiss="modal"
              >
                Cerrar ventana
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade c-modal-info"
        id="modalRiesgo"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="c-modal-info__header">
              <h5 className="c-modal-info__title">Gestión de riesgo</h5>
            </div>
            <div className="modal-body">
              <p>
                Contamos con una amplia red de modelos cuantitativos que buscan
                eficientizar la colocación del capital de nuestros inversionistas
                en la manera que podamos rentabilizar sus inversiones al máximo,
                manteniendo el riesgo y los eventos de cola al mínimo, aunque
                todavía existentes.
              </p>
            </div>
            <div className="c-modal-info__footer">
              <button
                type="button"
                className="c-btn c-btn--outline-dark"
                data-bs-dismiss="modal"
              >
                Cerrar ventana
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade c-modal-info"
        id="modalDiversificacion"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="c-modal-info__header">
              <h5 className="c-modal-info__title">Diversificación</h5>
            </div>
            <div className="modal-body">
              <p>
                Gracias a nuestros Fondos Abiertos y Cerrados, puedes participar
                en portafolios con diferentes objetivos, perfiles de riesgo e
                inversiones, logrando asi economía de escala en tus inversiones y
                un mayor nivel de diversificación
              </p>
            </div>
            <div className="c-modal-info__footer">
              <button
                type="button"
                className="c-btn c-btn--outline-dark"
                data-bs-dismiss="modal"
              >
                Cerrar ventana
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade c-modal-info"
        id="modalImpacto"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="c-modal-info__header">
              <h5 className="c-modal-info__title">Impacto social</h5>
            </div>
            <div className="modal-body">
              <p>
                Nos enfocamos en brindar el mayor impacto social, económico y
                medio ambiental para el desarrollo de República Dominicana, así
                como buscamos brindar excelencia en la administración de los
                portafolios denuestros clientes.
              </p>
            </div>
            <div className="c-modal-info__footer">
              <button
                type="button"
                className="c-btn c-btn--outline-dark"
                data-bs-dismiss="modal"
              >
                Cerrar ventana
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OurPillars