import React from 'react'
import support_svg from '../../assets/images/support.svg';

const ModalContactSuccess = () => {
    const handleModalClose = () => {
        const getModalSuccess = document.getElementById("modalSuccess");
        getModalSuccess.classList.remove('show');
        getModalSuccess.style = "display: none";
    }
    return (
        <div
            className="modal fade c-modal-success"
            id="modalSuccess"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="c-modal-success__icon">
                            <img src={support_svg} alt=""/>
                        </div>
                        <h3 className="c-modal-success__title">
                            ¡Hola <b></b>!
                        </h3>
                        <p className="c-modal-success__text">
                            Alguien de nuestro equipo estará <b>verificando</b> tu información. Gracias por contactarnos.
                        </p>
                        <div className="c-modal-success__footer mt-4">
                            <button
                                type="button"
                                className="c-btn c-btn--outline"
                                data-bs-dismiss="modal"
                                onClick={handleModalClose}
                            >
                                Llamar ahora
                            </button>
                            <button
                                type="button"
                                className="c-btn c-btn--white"
                                data-bs-dismiss="modal"
                                onClick={handleModalClose}
                            >
                                Listo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalContactSuccess