import React from 'react';
import handleStrapiUrl from '../../../lib/handle-strapi-url';
import trophy_svg from '../../../assets/images/trophy.svg';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useQuery, gql } from '@apollo/client';

const ABOUT_HERO = gql`
  query GetAboutHero {
    hero {
      data {
        id
        attributes {
          background {
            data {
              id
              attributes {
                url
                caption
                alternativeText
                updatedAt
                createdAt
              }
            }
          }
          boxLeft
          boxRight
          body
        }
      }
    }
  }
`

const Hero = () => {
  const { loading, error, data } = useQuery(ABOUT_HERO);
    
  if(error){
    console.log('SLIDESHOW: ', error);
  }

  if(loading){
    return <p>Cargando SLIDESHOW...</p>
  }

  return (
    <div className="l-hero-about" 
      style={{
        backgroundImage: `url(${handleStrapiUrl(data?.hero?.data?.attributes?.background?.data?.attributes?.url)})`
      }}
    >
      <div className="l-hero-about__content">
        <div className="row">
          <div className="col-lg-7 order-2 order-lg-0">
            <h2 className="l-hero-about__title">Quienes somos</h2>
            <div className="l-hero-about__text">
              <ReactMarkdown
                children={data?.hero?.data?.attributes?.body} 
                remarkPlugins={[remarkGfm]}
              />
            </div>
          </div>
          <div className="col-lg-5 order-0 order-1">
            <div className="l-hero-about__cards">
              <div className="c-card-hero">
                <div className="c-card-hero__text">Activos Bajo Administración</div>
                <div><span>US</span>{data?.hero?.data?.attributes?.boxRight} <span>MM</span></div>
                <img
                  src={trophy_svg}
                  alt=""
                  className="c-card-hero__image"
                />
              </div>
              <div className="c-card-hero">
                <div className="c-card-hero__text u-font-bold mb-2">
                  Feller Rate
                </div>
                <div>{data?.hero?.data?.attributes?.boxLeft}</div>
                <div className="c-card-hero__text mt-2">
                  Calificación de Solvencia
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero   