import React from 'react';
import icon_logo_svg from '../../assets/images/icon-logo.svg';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const FaqItem = ({
    id,
    title,
    body
}) => {
  return (
    <div id={`question${id}`} className="c-faq-item mt-5 mb-5">
        <div className="c-faq-item__header">
            <img className="c-faq-item__icon" src={icon_logo_svg} alt=""/>
            <h4 className="c-faq-item__title">
                {title}
            </h4>
        </div>
        <div className="c-faq-item__body">
            <ReactMarkdown 
                children={body}  
                remarkPlugins={[remarkGfm]}
            />
        </div>
    </div>
  )
}

export default FaqItem